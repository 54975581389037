import React, { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { DonutChart, Spinner, Link } from "@abb/abb-common-ux-react";
import "./alarm-summary.css";
import { BASE_URL, ERROR_LIST } from "../../strings";
import { getAlarmSummaryLegendsColor } from "../../util";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import { getToken } from "../Authentication/get-token";
import config from "./alarm-summary-config.json";

function AlarmSummary() {
  const { setSpinner, startTS, endTS } = useContext(RootContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [hoverItem, setHoverItem] = useState(null);
  const SUBSTATION_ID = localStorage.getItem("selectedSubstationId");

  const fetchAlarmSumData = useCallback(async () => {
    setIsLoading(true);
    setError(false);
    try {
      const response = await axios.get(
        `${BASE_URL}alarm-notification-summary?startDateTime=${startTS}&endDateTime=${endTS}&substationId=${SUBSTATION_ID}`, 
        getToken()
      );
      const jsonArray = response.data.alarmSummary.data;
      const updatedJsonArray = jsonArray.map((obj) => ({
        ...obj,
        label: obj?.key,
        color: getAlarmSummaryLegendsColor(obj.key),
      }));
      setData(updatedJsonArray);
    } catch (err) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [startTS, endTS, SUBSTATION_ID]);

  useEffect(() => {
    setSpinner(true);
    fetchAlarmSumData();
  }, [fetchAlarmSumData, setSpinner]);

  const dominantValue = useMemo(() => {
    return data
      .filter((item) => !hiddenItems.includes(item.key))
      .sort((a, b) => b.value - a.value)[0];
  }, [data, hiddenItems]);

  const formatLegendItem = useCallback(
    (d) => (
      <span>
        <span style={{ fontSize: "80%" }}>{d?.value}</span>
        <span style={{ color: d.color }}>{d?.label}</span>
      </span>
    ),
    []
  );

  if (!config.enabled) {
    return null; // DonutChart display disabled via config
  }

  return (
    <>
      {isLoading ? (
        <Spinner style={{ margin: "auto" }} color="dark-grey" sizeClass="small" />
      ) : error ? (
        <span style={{ margin: "auto", color: "dark-grey" }}>
          {ERROR_LIST.ERROR_FETCH_DATA}
          <br />
          <Link
            style={{ color: "#1f1f1f" }}
            onClick={fetchAlarmSumData}
          >
            Retry
          </Link>
        </span>
      ) : data.length > 0 ? (
        <DonutChart
        className="alarm-sum-chart"
          legendPosition={config.legendPosition}
          allowValueHiding={config.allowValueHiding}
          disableHoverOnChart={!config.enableHoverOnChart}
          disableHoverOnLegend={!config.enableHoverOnLegend}
          legendItemFormatter={
            config.customLegendTemplate ? formatLegendItem : undefined
          }
          label={
            config.showLabel
              ? hoverItem?.label || dominantValue?.label
              : undefined
          }
          valueText={
            config.showValueText
              ? hoverItem?.value || dominantValue?.value
              : undefined
          }
          description={
            config.showDescription ? `${hoverItem ? " " : ""}` : undefined
          }
          hiddenItems={hiddenItems}
          onHiddenItemsChanged={setHiddenItems}
          onHover={(item) =>
            setHoverItem(data.find((i) => i.key === item))
          }
          onBlur={() => setHoverItem(null)}
          data={data}
        />
      ) : (
        <p className="center-text-error">
          {ERROR_LIST.ALARM_SUMMARY_EMPTY_DATA}
        </p>
      )}
    </>
  );
}

export default AlarmSummary;
