import React, { useState, useEffect,useCallback } from "react";
import { Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import "./forgot-password.css";
import { MESSAGES } from "../../strings";
import { Button, Dialog, Link } from "@abb/abb-common-ux-react";
import forgotPasswordData from "./forgot-password.json"
import { validateJson, isJsonValid } from '../../util';

const ForgotPassword = () => {
    const [username, setUsername] = useState('');
    const [resetPasswordStep, setResetPasswordStep] = useState(1);
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [isValidnewpwd, setisValidnewpwd] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [code, setCode] = useState('');
    const [formValues, setFormValues] = useState({
        email: "",
        code: "",
        newpassword: "",
        confirmpassword: "",
    });


    const validateForgotPasswordData=useCallback(()=>{
        const jsonString = JSON.stringify( forgotPasswordData); 
         
        if (isJsonValid(jsonString)) {
          validateJson( forgotPasswordData,forgotPasswordData.forgotSchema); 
        } else {
          
          console.error('Invalid JSON syntax: Missing braces or commas.');
        }
      }, [forgotPasswordData]);
   

    useEffect(() => {
        validateForgotPasswordData();

    }, [validateForgotPasswordData]);
 



    
    function validateEmail(email) {
        const emailRegex = new RegExp(forgotPasswordData.validation.email.regex);
        return emailRegex.test(email); 
      }
  

    useEffect(() => {

    }, [formErrors, isValid, isValidnewpwd])

    const handleEmailChange = async (e) => {
        setFormErrors({})
        setUsername(e.target.value)
        setFormValues(prevState => ({ ...prevState, [e?.target?.id]: e.target.value }));
    }

    const handlePasswordChange = (e) => {
        let newPwd = e.target.value;
        setFormValues(prevState => ({ ...prevState, [e?.target?.id]: newPwd }));

        const isValidPassword = checkPwdRules(e.target.value)
        setIsValid(isValidPassword)
        if (!isValidPassword) {
            setFormErrors(prevState => ({ ...prevState, passowrd:  forgotPasswordData.validation.password.errorMessage }))
        } else {
            setFormErrors({})
        }
    };
    const handleConfirmPwd = (e) => {
        const newValue = e.target.value;
        setInputValue((prevValue) => {
            return newValue;
        });
    };
    const handleConfirmCode = (e) => {
        const newValue = e.target.value;
        setCode((prevValue) => {
            return newValue;
        });
    };

    const checkPwdRules = (newPassword) => {
        const hasNumber = new RegExp(forgotPasswordData.hasNumber).test(newPassword);
        const hasSpecialChar = new RegExp(forgotPasswordData.hasSpecialChar).test(newPassword);
        const hasUpperCase = new RegExp(forgotPasswordData.hasUpperCase).test(newPassword);
        const hasLowerCase = new RegExp(forgotPasswordData.hasLowerCase).test(newPassword);
        return hasNumber && hasSpecialChar && hasUpperCase && hasLowerCase;
    }

  
    
    const sendVerificationCode = async () => {
        let getVerificationCodeErrors = {}
        if (!validateEmail(formValues.email)) {
           
            setFormErrors(prevState => ({ ...prevState, email: forgotPasswordData.errors.emailError.errorMessage }))
            getVerificationCodeErrors.email = forgotPasswordData.errors.emailError.errorMessage
        }
        if (Object.keys(getVerificationCodeErrors).length === 0) {

            try {
                await Auth.forgotPassword(username);
                setResetPasswordStep(2);
                setFormValues(prevState => ({
                    ...prevState,
                    code: "",
                    newpassword: "",
                }));
                setFormErrors({})
            } catch (error) {
                if (error.message === forgotPasswordData.errors.userDisabled.msg) {
                    setFormErrors(prevState => ({ ...prevState, unauthmessage: forgotPasswordData.errors.userDisabled.message }))
                } else if (error.code === forgotPasswordData.errors.userDisabled.code) {
                    setFormErrors(prevState => ({ ...prevState, unauthmessage: forgotPasswordData.errors.userNotFound.message }))
                } else if(error.code === forgotPasswordData.errors.accountNotVerified.code && error.message === forgotPasswordData.errors.accountNotVerified.additionalMessage) {
                    setFormErrors(prevState => ({ ...prevState, unauthmessage: forgotPasswordData.errors.accountNotVerified.message }))
                } else if (error.toString().includes("AuthError") == false) {
                    setFormErrors(prevState => ({ ...prevState, unauthmessage: error.message }))
                }
            }
        }
    };
    const cancelForgotPwd = () => {
        navigate("../");
    }

    const checkVerificationCode = async () => {
      
        setFormErrors({});

        let error_code = "";

        console.log("Form errors: ", formErrors);

        const codeRegex = /^\d{6}$/;

        if (!code || code.trim() === '') {
   
            error_code = forgotPasswordData.errors.checkVarificationCode.emptyCode;
        } else if (!codeRegex.test(code)) {
         
            error_code = forgotPasswordData.errors.checkVarificationCode.invalidCode;
        }

        if (error_code !== "") {
            setFormErrors(prevState => ({ ...prevState, code: error_code}));
            console.log("Form errors: ", formErrors);
            return;
        }

        if (error_code === "") {
            try {
               
                setCode(code);
                setResetPasswordStep(3);
            } catch (error) {
                setFormErrors(prevState => ({ ...prevState, unauthmessage: error.message }))
            }
        }

        console.log("Form errors: ", formErrors);
    };

    const resetPassword = async () => {

        checkPwdRules(formValues.password)

        if (formValues.password != inputValue) {
            setFormErrors(prevState => ({ ...prevState, confirmpassword: forgotPasswordData.validation.password.confirmPasswordMismatch }))
            setisValidnewpwd(true)
        } else {
            setisValidnewpwd(false)

            setFormErrors({})
        }
        if (Object.keys(formErrors).length === 0) {
            try {
                await Auth.forgotPasswordSubmit(formValues.email, code, inputValue);
                setResetPasswordStep(4);
                
            } catch (error) {
                if(error.code === forgotPasswordData.errors.codeMismatch.code) {
                    setFormErrors(prevState => ({ ...prevState, code: forgotPasswordData.errors.codeMismatch.message }))
                    console.log(formErrors)
                    setCode('')
                    setIsValid(false)
                    setResetPasswordStep(2);
                } else {
                    setFormErrors(prevState => ({ ...prevState, unauthmessage: error.message }))
                }
            }
        }
        };
    const resendVerificationCode = async () => {
        try {
            await Auth.resendSignUp(username);

        } catch (error) {
            console.error(forgotPasswordData.errors.resendVerificationError.logMessage, error);
        }
    };

    return (
        <div className="ABB_CommonUX_LoginScreen__root ABB_CommonUX_LoginScreen__light login-container">

            <div className="ABB_CommonUX_LoginScreen__header">
                <div />
                <div className="ABB_CommonUX_LoginScreen__logo" />
            </div>
            <div className="ABB_CommonUX_LoginScreen__contentWrapper">

                <div className="ABB_CommonUX_LoginScreen__content">
             
                    
                    {forgotPasswordData.enable && (
                            <h1 className="ABB_CommonUX_LoginScreen__productName">
                            {forgotPasswordData.labels.forgotPasswordTitle}
                            </h1>
                        )}

                                        <div className="ABB_CommonUX_LoginScreen__form">

                        {resetPasswordStep === 1 &&
                            <>
                                <div className="ABB_CommonUX_LoginScreen__formRow">

                                 

                                              {forgotPasswordData.enable &&(
                                               <div
                                               className="ABB_CommonUX_LoginScreen__customContent"
                                               style={{ fontSize: "14px", fontWeight: 600 }}
                                               >
                                               {forgotPasswordData.labels.forgotPasswordInstructions}
                                               <div />
                                               </div>
                                              )}      

                                </div>
                                <div className="ABB_CommonUX_LoginScreen__formRow" style={{ width: "400px", fontSize: "14px", fontWeight: 500 }}>
                                    <div className="ABB_CommonUX_LoginScreen__password">
                                        <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                                            <div className="ABB_CommonUX_Input__labelContainer">
                                              
                                        {forgotPasswordData.enable &&(
                                          <span
                                          className="ABB_CommonUX_Input__label"
                                          style={{ fontSize: "14px", fontWeight: 500 }}
                                          >
                                          {forgotPasswordData.labels.emailAddressLabel}
                                          </span>
                                        )} 

                                            </div>
                                            {forgotPasswordData.enable &&(
                                            <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">

                                            <input
                                            className="ABB_CommonUX_Input__inputElement"
                                            type={forgotPasswordData.inputs.emailInput.type}
                                            required={forgotPasswordData.inputs.emailInput.required}
                                            name={forgotPasswordData.inputs.emailInput.name}
                                            id={forgotPasswordData.inputs.emailInput.id}
                                            onChange={handleEmailChange} 
                                            />
                                        

                                         <span className="ABB_CommonUX_Input__iconWrapper">
                                                    <i className="ABB_CommonUX_Icon__root">
                                                        <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_envelope_16" />
                                                    </i>
                                                </span>
                                            </div>
                                              )}
                                            {formErrors.email && <span className="error-display">{formErrors.email}</span>}


                                            <br />
                                            <br />
                                            
                                            {forgotPasswordData.enable &&(
                                                 <button
                                                 className="ABB_CommonUX_Button__root ABB_CommonUX_Button__primaryblack ABB_CommonUX_Button__mediumSize ABB_CommonUX_Button__light ABB_CommonUX_Button__rounded"
                                                 id={forgotPasswordData.buttons.sendEmail.id}
                                                 onClick={sendVerificationCode}
                                                 >
                                                 <div>
                                                     <span className="ABB_CommonUX_Button__text ABB_CommonUX_Button__hasText">
                                                     {forgotPasswordData.buttons.sendEmail.text}
                                                     </span>
                                                 </div>
                                                 </button>
                                            )}
                                               

                                            &nbsp;&nbsp;
                                       
                                       {forgotPasswordData.enable &&(
                                         <button
                                         className="ABB_CommonUX_Button__root ABB_CommonUX_Button__primaryblack ABB_CommonUX_Button__mediumSize ABB_CommonUX_Button__light ABB_CommonUX_Button__rounded"
                                         id={forgotPasswordData.buttons.cancelForgotEmail.id}
                                         onClick={cancelForgotPwd}
                                         >
                                         <div>
                                             <span className="ABB_CommonUX_Button__text ABB_CommonUX_Button__hasText">
                                             {forgotPasswordData.buttons.cancelForgotEmail.text}
                                             </span>
                                         </div>
                                         </button>
                                       )}
                                           

                                        </div>
                                    </div>
                                </div>
                            </>}
                        {formErrors.unauthmessage && <span className="error-display-pwd">{formErrors.unauthmessage}</span>}

                        {resetPasswordStep === 2 &&
                            <> <div className="ABB_CommonUX_LoginScreen__formRow">
                               {forgotPasswordData.enable &&(
                                 <div
                                 className="ABB_CommonUX_LoginScreen__customContent"
                                 style={{ fontSize: "14px", fontWeight: 600 }}
                                 >
                                 {forgotPasswordData.labels.verificationCodeMessage}
                                 <div />
                                 </div>
                               )}
                               

                            </div>
                                <div className="ABB_CommonUX_LoginScreen__formRow" style={{ fontSize: "14px", fontWeight: 500 }}>
                                    <div className="ABB_CommonUX_LoginScreen__password">
                                        <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                                            <div className="ABB_CommonUX_Input__labelContainer">

                                                {forgotPasswordData.enable &&(
                                                      <span className="ABB_CommonUX_Input__label">

                                                      <b>{forgotPasswordData.labels.verificationCodeLabel}</b>
                                                     </span>
        
                                                )}
                                              
                                            </div>

                                            {forgotPasswordData.enable &&(
                                            <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                                                 <input
                                                     className="ABB_CommonUX_Input__inputElement"
                                                         type={forgotPasswordData.inputs.verifyCodeInput.type}
                                                         required={forgotPasswordData.inputs.verifyCodeInput.required}
                                                         name={forgotPasswordData.inputs.verifyCodeInput.name}
                                                         id={forgotPasswordData.inputs.verifyCodeInput.id}
                                                         onKeyUp={handleConfirmCode} 
                                                     />
 
                                                 </div>
                                            )}
                                            {formErrors.code && <span className="error-display">{formErrors.code}</span>}
                                            <br />
                                            <br />
                                            <div className="ABB_CommonUX_LoginScreen__buttons ABB_CommonUX_LoginScreen__flexSpring">
                                                <div className="ABB_CommonUX_LoginScreen__button">
                                                    
                                                    
                                                {forgotPasswordData.enable &&(
                                                     <button className="ABB_CommonUX_Button__root ABB_CommonUX_Button__primaryblack ABB_CommonUX_Button__mediumSize ABB_CommonUX_Button__light ABB_CommonUX_Button__rounded" id="send-email" onClick={checkVerificationCode} >
                                                        
                                                     <div>
                                                         
                                                         <span className="ABB_CommonUX_Button__text ABB_CommonUX_Button__hasText">
                                                         {forgotPasswordData.buttons.continueButton.text}
                                                         </span>
                                                      
                                                      </div>
                                                    
                                                 </button>
                                                )}
                                                  
                                                  {forgotPasswordData.enable &&(
                                                     <Button
                                                     sizeClass={forgotPasswordData.buttons.backButton.sizeClass}
                                                     text={forgotPasswordData.buttons.backButton.text}
                                                     type={forgotPasswordData.buttons.backButton.type}
                                                     style={forgotPasswordData.buttons.backButton.style}
                                                     onClick={() => setResetPasswordStep(1)}
                                                     />
                                                  )}
                                                  
                                                    {forgotPasswordData.enable &&(
                                                        <Link
                                                        style={forgotPasswordData.links.resendCode.style}
                                                        onClick={resendVerificationCode}
                                                        discreet={forgotPasswordData.links.resendCode.discreet}
                                                        >
                                                        {forgotPasswordData.links.resendCode.text}
                                                        </Link>
                                                    
                                                    )}
                                                   
                                                
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div></>}

                                {resetPasswordStep === 3 &&
                            <> <div className="ABB_CommonUX_LoginScreen__formRow">
                                 
                                 {forgotPasswordData.enable &&(
                                     <div 
                                     className="ABB_CommonUX_LoginScreen__customContent" 
                                     style={{ fontSize: "14px", fontWeight: 600 }}
                                     >
                                     {forgotPasswordData.labels.newPasswordMessage}
                                     </div>
 
                                 )}

                               
                            </div>
                                <div className="ABB_CommonUX_LoginScreen__formRow" style={{ fontSize: "14px", fontWeight: 500 }}>
                                    <div className="ABB_CommonUX_LoginScreen__password">
                                        <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                                            {formErrors.code && <span className="error-display">{formErrors.code}</span>}
                                            <br />

                                            <div className="ABB_CommonUX_Input__labelContainer">
                                                 
                                                 
                                                {forgotPasswordData.enable &&(
                                                      <span className="ABB_CommonUX_Input__label">
                                                      <b>{forgotPasswordData.labels.newPasswordLabel}</b>
                                                      </span>
                                                )}
                                              

                                            </div>
                                            {forgotPasswordData.enable &&(
                                            <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                                                
                                                     <input
                                                     className="ABB_CommonUX_Input__inputElement"
                                                    type={forgotPasswordData.inputs.passwordInput.type}
                                                    required={forgotPasswordData.inputs.passwordInput.required}
                                                    name={forgotPasswordData.inputs.passwordInput.name}
                                                    id={forgotPasswordData.inputs.passwordInput.id}
                                                    onChange={handlePasswordChange} 
                                                />
                                              

                                               


                                            </div>
                                              )}
                                            {formErrors.passowrd || !isValid ? <span className="error-display">{formErrors.passowrd}</span> : <span className="valid-display-pwd">Password is valid</span>}
                                            <br />
                                            <div className="ABB_CommonUX_Input__labelContainer">
                                                
                                                {forgotPasswordData.enable &&(
                                                    
                                                <span className="ABB_CommonUX_Input__label">
                                                <b>{forgotPasswordData.labels.confirmNewPasswordLabel}</b>
                                            </span>
                                                )}


                                            </div>

                                            
                                          {forgotPasswordData.enable &&(
                                           <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">

                                           <input
                                                   className="ABB_CommonUX_Input__inputElement"
                                                   type={forgotPasswordData.inputs.confirmPasswordInput.type}
                                                   required={forgotPasswordData.inputs.confirmPasswordInput.required}
                                                   name={forgotPasswordData.inputs.confirmPasswordInput.name}
                                                   id={forgotPasswordData.inputs.confirmPasswordInput.id}
                                                   onKeyUp={handleConfirmPwd} 
                                               />
                                       </div>
                                          )}
                                           

                                            {isValidnewpwd && <span className="error-display">{formErrors.confirmpassword}</span>}
                                            <br />
                                            <br />
                                            <div className="ABB_CommonUX_LoginScreen__buttons ABB_CommonUX_LoginScreen__flexSpring">
                                                <div className="ABB_CommonUX_LoginScreen__button">
                                                {forgotPasswordData.enable &&(
                                                    <button className="ABB_CommonUX_Button__root ABB_CommonUX_Button__primaryblack ABB_CommonUX_Button__mediumSize ABB_CommonUX_Button__light ABB_CommonUX_Button__rounded" id="send-email" onClick={resetPassword} >
                                                        
                                                        <div>
                                                        
                                                        
                                                     <span className="ABB_CommonUX_Button__text ABB_CommonUX_Button__hasText">
                                                            {forgotPasswordData.buttons.continue_ButtonText.text}
                                                            </span>

                                                    
                                                       

                                                        </div>
                                                    </button> )}
                                                    &nbsp;&nbsp; &nbsp;&nbsp;
                                                    {forgotPasswordData.enable &&(
                                                        <Button
                                                        sizeClass={forgotPasswordData.buttons.back_Button.sizeClass}
                                                        text={forgotPasswordData.buttons.back_Button.text}
                                                        type={forgotPasswordData.buttons.back_Button.type}
                                                        onClick={() => { setCode(''); setResetPasswordStep(2)}}
                                                    />
                                                    )}
                                                    
                                                    &nbsp;&nbsp; &nbsp;&nbsp;
                                                     
                                                    {forgotPasswordData.enable &&(
                                                        <Button
                                                        sizeClass={forgotPasswordData.buttons.cancel_Button.sizeClass}
                                                        text={forgotPasswordData.buttons.cancel_Button.text}
                                                        type={forgotPasswordData.buttons.cancel_Button.type}
                                                        onClick={cancelForgotPwd}
                                                    />
                                                    )}

                                                    
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div></>}

                                {resetPasswordStep === 4 &&
                                    <div class="ReactModal__Overlay ReactModal__Overlay--after-open ABB_CommonUX_Dialog__overlay ABB_CommonUX_Dialog__halfOpacityBackdrop ABB_CommonUX_Dialog__light">
                                        <Dialog 
                                        className="signup-dialog-container"
                                        title={MESSAGES.FORGOT_PASSWORD_TITLE}
                                        >
                                        <p>{MESSAGES.FORGOT_PASSWORD_BODY}</p>
                                        
                                        
                                        {forgotPasswordData.enable &&(
                                            
                                        <Button
                                        sizeClass={forgotPasswordData.buttons.backToLoginButton.sizeClass}
                                        text={forgotPasswordData.buttons.backToLoginButton.text}
                                        type={forgotPasswordData.buttons.backToLoginButton.type}
                                        onClick={cancelForgotPwd}
                                    />
                                        )}

                                        </Dialog>
                                    </div>
                                }
                    </div>



                    
                </div>

            </div>


        </div>

    );
};

export default ForgotPassword;
