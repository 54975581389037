import React, { useEffect, useState } from "react";
import {
  TabItem,
  TabControl,
  Button,
  Datagrid,
  Dialog,
  Spinner,
  Icon,
  WithPopup,
  Popup,
} from "@abb/abb-common-ux-react";

import jsonSchema from "./manage-user-schema.json";
import uiSchema from "./manage-user-uischema.json";

import { useNavigate } from "react-router-dom";
import "./manage-users.css";
import { BASE_URL } from "../../strings";
import EditUser from "./edit-user";
import EditSubstation from "./edit-substation";
import axios from "axios";
import NotificationExample from "../../components/Notification/notification";
import { getToken } from "../../components/Authentication/get-token";
import { decryptData, validateJsonSchema, isJsonValidSchema } from "../../util";

const ManageUsers = () => {
  const [isManageUserEnabled, setIsManageUserEnabled] = useState(false);
  const [isSubstationMappingEnabled, setIsSubstationMappingEnabled] =
    useState(false);
  const [data, setdata] = React.useState([]);
  const [isEdit] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [subData, setSubData] = React.useState([]);
  const [isSubEdit, setisSubEdit] = React.useState(false);
  const [base64String, setBase64String] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isUserDelete, setisUserDelete] = useState(false);
  const [subId, setSubId] = useState("");
  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const navigate = useNavigate();
  const [closeOnEscape] = React.useState(true);
  const [trigger] = React.useState("hover");
  const [position] = React.useState("bottom center right");
  const [isOpen, setIsOpen] = React.useState(false);
  const [isControlledOpen, setIsControlledOpen] = React.useState(false);
  const [rbacManageData, setRbacManageData] = useState([]);

  useEffect(() => {
    const jsonString = JSON.stringify(jsonSchema);
    if (isJsonValidSchema(jsonString)) {
      console.log('JSON being validated: notification', jsonSchema);
      validateJsonSchema(jsonSchema); // Pass ignored properties
    } else {
      console.error('Invalid JSON syntax: Missing braces or commas.');
    }
  }, []);

  const usersColumn = React.useMemo(
    () =>
      [
        jsonSchema.properties.manageuser.properties.users.items.properties.name
          .enable && {
          fieldKey: "name",
          title:
            jsonSchema.properties.manageuser.properties.users.items.properties
              .name.title,
        },
        jsonSchema.properties.manageuser.properties.users.items.properties.email
          .enable && {
          fieldKey: "email",
          title:
            jsonSchema.properties.manageuser.properties.users.items.properties
              .email.title,
        },
        jsonSchema.properties.manageuser.properties.users.items.properties
          .contactNumber.enable && {
          fieldKey: "contactNumber",
          title:
            jsonSchema.properties.manageuser.properties.users.items.properties
              .contactNumber.title,
        },
        jsonSchema.properties.manageuser.properties.users.items.properties.role
          .enable && {
          fieldKey: "role",
          title:
            jsonSchema.properties.manageuser.properties.users.items.properties
              .role.title,
        },
        jsonSchema.properties.manageuser.properties.users.items.properties
          .operationArea.enable && {
          fieldKey: "operationArea",
          title:
            jsonSchema.properties.manageuser.properties.users.items.properties
              .operationArea.title,
        },
        jsonSchema.properties.manageuser.properties.users.items.properties
          .status.enable && {
          fieldKey: "status",
          title:
            jsonSchema.properties.manageuser.properties.users.items.properties
              .status.title,
        },
        jsonSchema.properties.manageuser.properties.users.items.properties
          .action.enable && {
          fieldKey: "action",
          title:
            jsonSchema.properties.manageuser.properties.users.items.properties
              .action.title,
        },
      ].filter(Boolean), // Filters out any falsy values (like `false` or `undefined`)
    []
  );

  const [columns] = React.useState(usersColumn);
  const [delButton, setDelButton] = React.useState(false);

  const initialSubColumns = React.useMemo(
    () =>
      [
        jsonSchema.properties.actions.properties.properties.operationArea
          .enabled && {
          fieldKey: "operationArea",
          title:
            jsonSchema.properties.actions.properties.properties.operationArea
              .title,
        },
        jsonSchema.properties.actions.properties.properties.substationName
          .enabled && {
          fieldKey: "substationName",
          title:
            jsonSchema.properties.actions.properties.properties.substationName
              .title,
        },
        jsonSchema.properties.actions.properties.properties.substationCode
          .enabled && {
          fieldKey: "substationCode",
          title:
            jsonSchema.properties.actions.properties.properties.substationCode
              .title,
        },
        jsonSchema.properties.actions.properties.properties.substationType
          .enabled && {
          fieldKey: "substationType",
          title:
            jsonSchema.properties.actions.properties.properties.substationType
              .title,
        },
        jsonSchema.properties.actions.properties.properties.coordinates
          .enabled && {
          fieldKey: "coordinates",
          title:
            jsonSchema.properties.actions.properties.properties.coordinates
              .title,
        },
        jsonSchema.properties.actions.properties.properties.action.enabled && {
          fieldKey: "action",
          title:
            jsonSchema.properties.actions.properties.properties.action.title,
        },
      ].filter(Boolean), // Filters out any falsy values (like `false` or `undefined`)
    []
  );
  const [subcolumns] = React.useState(initialSubColumns);
  const [isnotify, setisnotify] = useState("");

  useEffect(() => {
    fetchUserList();
    fetchSubstationData();
  }, []);

  useEffect(() => {
    getButtonDelete();
  }, [subData]);

  const getButtonDelete = () => {
    if (subData.length === 1) {
      setDelButton(true);
    }
  };

  const handleEUserdit = (userId) => {
    // Redirect to the edit page with the user ID
    navigate(`/edit-user/${userId}`);
  };

  const handleUserDelete = async () => {
    // Delete the user from the state
    //setUsers(users.filter((user) => user.id !== userId));
    try {
      const url = BASE_URL + `rbac-delete?id=${currentUserId}`;
      await axios.delete(url, getToken());
      await fetchSubstationData();
      setisUserDelete(false);
      setisnotify("remove-user");

      // Auto refresh after delete user
      const response1 = await axios.get(BASE_URL + `rbac-get`, getToken());
      setRbacManageData(response1.data);
      localStorage.setItem(
        "rbacManagerUsersList",
        JSON.stringify(response1.data)
      );
      window.location.reload();
      //
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const fetchUserList = async () => {
    setispin(true);
    try {
      const response = JSON.parse(localStorage.getItem("rbacManagerUsersList"));
      let formatedUserListData = response?.map((userInfo, userInfoIndex) => ({
        fields: {
          name: userInfo?.givenName,
          email: userInfo?.email,
          contactNumber: userInfo?.phoneNumber,
          operationArea:
            userInfo?.operationAreas.length < 2 ? (
              userInfo?.operationAreas
            ) : (
              <WithPopup>
                <span style={{ cursor: "pointer" }}>
                  {userInfo?.operationAreas}
                </span>
                <Popup
                  trigger={trigger}
                  position={position}
                  closeOnEscape={closeOnEscape}
                  onOpen={() => {
                    setIsOpen(true);
                    setIsControlledOpen(true);
                  }}
                >
                  <div className="pop_up">
                    {userInfo?.operationAreas.map((item, index) => {
                      return (
                        <p key={item.email}>
                          <span>{item}</span>
                        </p>
                      );
                    })}
                  </div>
                </Popup>
              </WithPopup>
            ),
          status: userInfo?.activeStatus.toString() == "true" ? "Enabled" : "",
          role: userInfo?.role,
          action: (
            <>
              {jsonSchema.properties.manageuser.properties.users.items
                .properties.editButton.enable && (
                <Button
                  type={
                    jsonSchema.properties.manageuser.properties.users.items
                      .properties.editButton.properties.parameters.items.type
                  }
                  onClick={() => {
                    handleEUserdit(userInfo.email);
                  }}
                  text={
                    jsonSchema.properties.manageuser.properties.users.items
                      .properties.editButton.properties.text.default
                  }
                  shape={
                    jsonSchema.properties.manageuser.properties.users.items
                      .properties.editButton.properties.shape.default
                  }
                  style={uiSchema.manageUserUI.buttonGroup.buttons[0].style}
                />
              )}

              {jsonSchema.properties.manageuser.properties.users.items
                .properties.removeButton.enable && (
                <Button
                  type={
                    jsonSchema.properties.manageuser.properties.users.items
                      .properties.removeButton.properties.parameters.items.type
                  }
                  onClick={() => {
                    setCurrentUserId(userInfo.email);
                    setisUserDelete(true);
                  }}
                  disabled={
                    userInfo.email ===
                    decryptData(
                      localStorage.getItem("logged_in_user")
                    )?.replace(/"/g, "")
                  }
                  text={
                    jsonSchema.properties.manageuser.properties.users.items
                      .properties.removeButton.properties.text.default
                  }
                  shape={
                    jsonSchema.properties.manageuser.properties.users.items
                      .properties.removeButton.properties.shape.default
                  }
                  style={uiSchema.manageUserUI.buttonGroup.buttons[1].style}
                />
              )}
            </>
          ),
        },
      }));
      setdata(formatedUserListData);
      setispin(false);
    } catch (error) {
      if (error.response) {
        setispin(false);
        setError(true);
      } else {
        setError(true);
        setispin(false);
      }
      
    } 
  };

  const openDeleteDialog = async (id) => {
    setIsDeleteDialogOpen(true);
    setSubId(id);
  };

  const handleSubstationDelete = async () => {
    const url = BASE_URL + `substation-details-delete?substationId=${subId}`;
    await axios.delete(url, getToken());
    setisnotify("sub-delete");
    await fetchSubstationData();
    setIsDeleteDialogOpen(false);
    setisnotify("");
  };
  const fetchSubstationData = async () => {
    let baseURL = BASE_URL + `substation-details`;
    try {
      const response = await axios.get(baseURL, getToken());
      let substationData = response?.data;
      let formatedSubData = substationData.map((v, i) => ({
        fields: {
          operationArea: v?.operationArea,
          substationName: v?.name,
          substationCode: v?.substationId,
          substationType: v?.type,
          coordinates: v?.coordinates,
          action: (
            <>
              {jsonSchema.properties.actions.properties.editButton.enabled && (
                <Button
                  type={
                    jsonSchema.properties.actions.properties.editButton
                      .properties.type.enum[0]
                  }
                  onClick={() => {
                    localStorage.setItem("substation", JSON.stringify(v));
                    setisSubEdit(true);
                  }}
                  text={
                    jsonSchema.properties.actions.properties.editButton
                      .properties.text.default
                  }
                  shape={
                    jsonSchema.properties.actions.properties.editButton
                      .properties.shape.enum[0]
                  }
                  style={uiSchema.actions.editButton["ui:options"].style}
                />
              )}
              {jsonSchema.properties.actions.properties.deleteButton
                .enabled && (
                <Button
                  href={
                    "data:text/plain;charset=utf-8," +
                    encodeURIComponent("some text here")
                  }
                  type={
                    jsonSchema.properties.actions.properties.deleteButton
                      .properties.type.enum[0]
                  }
                  text={
                    jsonSchema.properties.actions.properties.deleteButton
                      .properties.text.default
                  }
                  shape={
                    jsonSchema.properties.actions.properties.deleteButton
                      .properties.shape.enum[0]
                  }
                  disabled={v?.substationId === "BAY-DS-8451"}
                  onClick={() => openDeleteDialog(v?.substationId)}
                  style={uiSchema.actions.deleteButton["ui:options"].style}
                />
              )}
            </>
          ),
        },
      }));
      setSubData(formatedSubData);
      setispin(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchSubstationData();
  }, [isSubEdit]);

  const goToSettings = () => {
    navigate("../substation-explorer");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setIsUploaded(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64 = event.target.result.split(",")[1]; // Extract the base64 part

        setBase64String(base64);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFileUpload = () => {
    const headers = {
      "Content-Type": "application/pdf",
      Authorization: getToken().headers.Authorization, // Include the token in the Authorization header
    };

    const url = BASE_URL + `substation-details-upload`;

    fetch(url, {
      method: "POST",
      body: base64String,
      headers: headers,
    })
      .then((response) => {
        if (response.ok) {
          setisnotify("sub-success");
          fetchSubstationData();
          setIsUploaded(false);

          handleFileUploadCancel();
        } else {
          setisnotify("sub-failure");
          console.error("Failed to upload and post the file content.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleFileUploadCancel = () => {
    setIsUploaded(false);
    const fileInput = document.querySelector('input[type="file"]');
    fileInput.value = "";
    setBase64String("");
  };

  useEffect(() => {
    manageUserEnablingFunction();
  }, []);

  const manageUserEnablingFunction = () => {
    const locallyStoredUserInfo = localStorage.getItem("USER_INFO");
    if (locallyStoredUserInfo) {
      try {
        const userInfo = JSON.parse(locallyStoredUserInfo);
        const userManagementValue = toBoolean(
          userInfo.permissions.userManagement
        );

        setIsManageUserEnabled(userManagementValue);
      } catch (error) {
        console.error("Error parsing JSON from local storage:", error);
        setIsManageUserEnabled(false);
      }
    } else {
      console.log("No user info found in local storage.");
      setIsManageUserEnabled(false);
    }
  };
  useEffect(() => {
    substationEnablingFunction();
  }, []);

  const substationEnablingFunction = () => {
    const locallyStoredUserInfo = localStorage.getItem("USER_INFO");
    if (locallyStoredUserInfo) {
      try {
        const userInfo = JSON.parse(locallyStoredUserInfo);
        const userManagementValue = toBoolean(
          userInfo.permissions.addSubstationMapping
        );
        console.log("Type:", typeof userManagementValue);
        setIsSubstationMappingEnabled(userManagementValue);
      } catch (error) {
        console.error("Error parsing JSON from local storage:", error);
        setIsSubstationMappingEnabled(false);
      }
    } else {
      console.log("No user info found in local storage.");
      setIsSubstationMappingEnabled(false);
    }
  };

  function toBoolean(value) {
    if (typeof value === "boolean") {
      return value;
    }
    if (typeof value === "string") {
      return value.toLowerCase() === "true";
    }
    return false;
  }

  const fileName = "SubstationMappingSample.xlsx";
  const fileUrl = `${process.env.PUBLIC_URL}/${fileName}`;

  return (
    <>
      {/* Notifications */}
      {isnotify === "remove-user" && (
        <NotificationExample notitype={"remove-user"} />
      )}
      {isnotify === "sub-success" && (
        <NotificationExample notitype={isnotify} />
      )}
      {isnotify === "sub-failure" && (
        <NotificationExample notitype={isnotify} />
      )}
      {isnotify === "sub-delete" && <NotificationExample notitype={isnotify} />}

      {/* Conditional Edit Views */}
      {isEdit || isSubEdit ? (
        <>
          {isSubEdit ? (
            <EditSubstation editfunc={setisSubEdit} />
          ) : (
            <EditUser />
          )}
        </>
      ) : (
        <div className="settings-page-content">
          <div className="row">
          
              <Icon sizeClass="small" name="abb/left-arrow" className="go-back" onClick={goToSettings}/>
              Back
          

          </div>
          <div className="row">
            <TabControl
              style={{ margin: "0px 16px" }}
              containerId="settings-tab-container"
              activeTab={activeTab}
              onTabChange={(oldIndex, newIndex) => setActiveTab(newIndex)}
            >
              <TabItem title="Manage Users" disabled={!isManageUserEnabled}>
                <div className="manage-user-container">
                  {(() => {
                    let content;

                    if (isSpin) {
                      content = (
                        <Spinner
                          style={{ margin: "auto" }}
                          color="dark-grey"
                          sizeClass="large"
                        />
                      );
                    } else if (isManageUserEnabled) {
                      content = (
                        <>
                          {jsonSchema.properties.manageuser.properties.header
                            .enable && (
                            <p className="get-user-profiles">
                              {
                                jsonSchema.properties.manageuser.properties
                                  .header.title
                              }
                            </p>
                          )}
                          <Datagrid
                            className="user-profiles-table"
                            data={data}
                            columns={data?.length > 0 ? columns : []}
                            rowHeight="small"
                            enableSorting={true}
                          />
                        </>
                      );
                    } else {
                      content = (
                        <div className="access-denied-container">
                          <p className="access-denied-message">
                            You do not have permission to access this section.
                            Please contact your administrator.
                          </p>
                        </div>
                      );
                    }

                    return content;
                  })()}
                </div>
                {isUserDelete &&
                  jsonSchema?.properties?.manageuser?.userdialog?.enabled && (
                    <Dialog
                      className="save-confirm-dialog-container"
                      showCloseButton={
                        jsonSchema?.properties?.manageuser?.userdialog
                          ?.properties?.showCloseButton?.default ?? true
                      }
                      closeOnEscape={
                        jsonSchema?.properties?.manageuser?.userdialog
                          ?.properties?.closeOnEscape?.default ?? true
                      }
                      dimBackground={
                        jsonSchema?.properties?.manageuser?.userdialog
                          ?.properties?.dimBackground?.default ?? true
                      }
                      title={
                        jsonSchema?.properties?.manageuser?.userdialog
                          ?.properties?.title?.default ?? true
                      }
                      standardButtonsOnBottom={[
                        {
                          text:
                            jsonSchema?.properties?.manageuser?.userdialog
                              ?.properties?.standardButtonsOnBottom?.items?.[0]
                              ?.properties?.text?.default ?? "No",
                          type:
                            jsonSchema?.properties?.manageuser?.userdialog
                              ?.properties?.standardButtonsOnBottom?.items?.[0]
                              ?.properties?.type?.default ?? "normal",
                          handler: () => setisUserDelete(false), // 'No' button handler
                        },
                        {
                          text:
                            jsonSchema?.properties?.manageuser?.userdialog
                              ?.properties?.standardButtonsOnBottom?.items?.[1]
                              ?.properties?.text?.default ?? "Yes",
                          type:
                            jsonSchema?.properties?.manageuser?.userdialog
                              ?.properties?.standardButtonsOnBottom?.items?.[1]
                              ?.properties?.type?.default ?? "primary-blue",
                          handler: () => handleUserDelete(), // 'Yes' button handler
                        },
                      ]}
                    />
                  )}
              </TabItem>

              {
                <TabItem
                  title="Substation Management"
                  disabled={!isSubstationMappingEnabled}
                >
                  {isnotify === "sub-success" && (
                    <NotificationExample notitype={isnotify} />
                  )}
                  {isnotify === "sub-failure" && (
                    <NotificationExample notitype={isnotify} />
                  )}

                  {isDeleteDialogOpen && (
                    <div>
                      {/* Trigger to show the dialog */}

                      {jsonSchema.properties.actions.properties.dialog
                        .enabled && (
                        <Dialog
                          className="save-confirm-dialog-container"
                          showCloseButton={
                            jsonSchema?.properties?.actions?.properties?.dialog
                              ?.properties?.showCloseButton?.default ?? false
                          }
                          closeOnEscape={
                            jsonSchema?.properties?.actions?.properties?.dialog
                              ?.properties?.closeOnEscape?.default ?? true
                          }
                          closeOnLostFocus={
                            jsonSchema?.properties?.actions?.properties?.dialog
                              ?.properties?.closeOnLostFocus?.default ?? true
                          }
                          hideBackground={
                            jsonSchema?.properties?.actions?.properties?.dialog
                              ?.properties?.hideBackground?.default ?? false
                          }
                          title={
                            jsonSchema?.properties?.actions?.properties?.dialog
                              ?.properties?.title?.default ?? "Default Title"
                          }
                          standardButtonsOnBottom={[
                            {
                              text:
                                jsonSchema?.properties?.actions?.properties
                                  ?.dialog?.properties?.standardButtonsOnBottom
                                  ?.items?.[0]?.properties?.text?.default ??
                                "Cancel",
                              type:
                                jsonSchema?.properties?.actions?.properties
                                  ?.dialog?.properties?.standardButtonsOnBottom
                                  ?.items?.[0]?.properties?.type?.default ??
                                "discreet-blue",
                              handler: () => setIsDeleteDialogOpen(false), // 'No' button handler
                            },
                            {
                              text:
                                jsonSchema?.properties?.actions?.properties
                                  ?.dialog?.properties?.standardButtonsOnBottom
                                  ?.items?.[1]?.properties?.text?.default ??
                                "Delete",
                              type:
                                jsonSchema?.properties?.actions?.properties
                                  ?.dialog?.properties?.standardButtonsOnBottom
                                  ?.items?.[1]?.properties?.type?.default ??
                                "discreet-blue",
                              handler: handleSubstationDelete, // 'Yes' button handler
                            },
                          ]}
                        />
                      )}
                    </div>
                  )}

                  <div className="manage-substaion-container">
                    {jsonSchema.enabled && (
                      <p className="get-user-profiles">{jsonSchema.title}</p>
                    )}
                    {jsonSchema.properties.title.enabled && (
                      <h6 className="manage-sub-title">
                        {jsonSchema.properties.title.title}
                      </h6>
                    )}

                    {jsonSchema.properties.fileInput.enabled && (
                      <input
                        className={uiSchema.fileInput.classNames}
                        type="file"
                        label={jsonSchema.properties.fileInput.title}
                        accept={uiSchema.fileInput["ui:options"].accept}
                        onChange={handleFileChange}
                      />
                    )}
                    <div className="manage-sub-buttons">
                      {isUploaded && (
                        <>
                          {jsonSchema.properties.actions.properties.upload
                            .enabled && (
                            <Button
                              style={
                                uiSchema.actions.upload["ui:options"].style
                              }
                              text="Upload"
                              shape={
                                uiSchema.actions.upload["ui:options"].shape
                              }
                              sizeClass={
                                uiSchema.actions.upload["ui:options"].sizeClass
                              }
                              type={uiSchema.actions.upload["ui:options"].type}
                              onClick={handleFileUpload}
                            />
                          )}
                          {jsonSchema.properties.actions.properties.clear
                            .enabled && (
                            <Button
                              text="Clear"
                              shape={uiSchema.actions.clear["ui:options"].shape}
                              sizeClass={
                                uiSchema.actions.clear["ui:options"].sizeClass
                              }
                              onClick={handleFileUploadCancel}
                            />
                          )}
                        </>
                      )}
                    </div>
                    {jsonSchema.properties.downloadTemplate.enabled && (
                      <a
                        href={fileUrl}
                        download={fileName}
                        className={
                          uiSchema.downloadTemplate["ui:options"].classNames
                        }
                      >
                        {jsonSchema.properties.downloadTemplate.title}
                      </a>
                    )}

                    <div>
                      {(() => {
                        let content;

                        if (isSubstationMappingEnabled) {
                          if (isSpin) {
                            content = (
                              <Spinner
                                style={{ margin: "auto" }}
                                color="dark-grey"
                                sizeClass="large"
                              />
                            );
                          } else {
                            content = (
                              <>
                                <h5 style={{ margin: "32px 0px 16px" }}>
                                  Substation List
                                </h5>
                                <Datagrid
                                  className="substation-table"
                                  data={subData}
                                  columns={subData.length > 0 ? subcolumns : []}
                                  rowHeight="small"
                                  fitToContent={true}
                                  sortOrder={{
                                    field: "operationArea",
                                    desc: true,
                                  }}
                                />
                              </>
                            );
                          }
                        } else {
                          content = (
                            <div className="access-denied-container">
                              <p className="access-denied-message">
                                You do not have permission to access this
                                section. Please contact your administrator.
                              </p>
                            </div>
                          );
                        }

                        return content;
                      })()}
                    </div>
                  </div>
                </TabItem>
              }
            </TabControl>
          </div>
          <div>
            <div id="settings-tab-container" />
          </div>
        </div>
      )}
    </>
  );
};

export default ManageUsers;
