import React, { useEffect, useState, useContext } from "react";
import {
  AppTopNaviDivider,
  AppTopNavi,
  Button,
  WithPopup,
  Popup,
  Icon
} from "@abb/abb-common-ux-react";
import avtar from "../../assets/Avatar.png";
import logo from "../../assets/Logo.png";
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { RootContext } from "../../contexts/root-context";
import { Breadcrumb } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import UserSession from "../Authentication/user-session";
import { BASE_URL_LOG, ROUTE_PAGES } from "../../strings";

import Form from "@rjsf/core";
import validator from '@rjsf/validator-ajv8';
import navbarSchema from './navbar-schema.json';
import navbarUISchema from './navbar-uischema.json';
import navbarData from './navbar-data.json';

import "./navbar.css";
function Navbar() {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [currentPage, setCurrentPage] = useState(location.pathname);
  const [monochrome] = useState(false);
  const [loggedInUserInfo, setLoggedInUserInfo] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isControlledOpen, setIsControlledOpen] = useState(false);
  const { setisnotify } = useContext(RootContext);

  useEffect(() => {
    // Update the current page state
    setCurrentPage(location.pathname)
    
    // Check if the pathname contains English alphabets and does not include "login"
    const hasEnglishAlphabet = /[a-zA-Z]/.test(location.pathname);
    const doesNotIncludeLogin = !location.pathname.toLowerCase().includes("login");

    if (hasEnglishAlphabet && doesNotIncludeLogin) {
      checkValidUsersData();
    }

    // Split the pathname into an array of path segments
    const pathSegments = location.pathname.split('/').filter((segment) => segment !== '');
    //console.log("pathSegments", pathSegments[1].toString().replace("%20", " "))
    if (location.pathname.includes("dashboard")) {
      isAssignedSubstation(pathSegments)
    }
    // Generate breadcrumbs based on the path segments
    const newBreadcrumbs = pathSegments.map((segment, index) => ({
      label: segment,
      path: `${pathSegments.slice(0, index + 1).join('/')}`,
    }));
    setBreadcrumbs(newBreadcrumbs);
    extractUserDetailsFromLocalStorage()
  }, [location.pathname]);

  // Utility function to check if the current substation is assigned
  const isAssignedSubstation = (pathSegments) => {
    const currentSubstation = pathSegments[1].toString().replace("%20DM", "")
    const allAssignedSub = JSON.parse(localStorage.getItem("subIds"))
    console.log(allAssignedSub)
    console.log(currentSubstation)
    const isFound = allAssignedSub.some(item => item.includes(currentSubstation));
    console.log(isFound)

    // alert(isFound)
    
    // If the substation is not found, navigate to the substation explorer and set notification
    if (isFound == false) {
      navigate("../" + ROUTE_PAGES.SUBSTATION_EXPLORER)
      setisnotify("unassigned-substation");
    }
    setTimeout(() => { setisnotify("") }, 5000)
  }

  // Utility function to extract user details from local storage
  const extractUserDetailsFromLocalStorage = () => {
    const userDetails = JSON.parse(localStorage.getItem("USER_INFO"));
    setLoggedInUserInfo(userDetails);
  }

  let navigate = useNavigate();
  const openSettingsPage = () => {
    navigate(`../${ROUTE_PAGES.SETTINGS}`);
  }
  const logout = async () => {
    try {
      await Auth.signOut();
      const userDetails = JSON.parse(localStorage.getItem("USER_INFO"));
      let USER_ID = userDetails.email;
      fetch(BASE_URL_LOG + `logout?userId=${USER_ID}`)
      localStorage.clear();
      setisnotify("");
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      navigate('/');
      reloadPage();
    }
  }
  const defaultBreadcrumb = {
    label: ROUTE_PAGES.HOME,
    path: ROUTE_PAGES.SUBSTATION_EXPLORER,
  };

  const reloadPage = () => {
    window.location.reload();
  }

  const checkValidUsersData = () => {
    const userInfo = localStorage.getItem("USER_INFO");
    if (userInfo === null) {
     // logout();
    }
  }

  // Utility function to check if a URL is valid
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };


  const CustomImageWidget = ({ uiSchema}) => {
    const imageUrl = navbarData.brand.logo.image_url;
    const validImageUrl = isValidUrl(imageUrl) ? imageUrl : logo; // Fallback to default logo if URL is invalid

    // console.log("uiSchema: ", uiSchema)
    return (
      <img
        className="avtar-icon"
        style={uiSchema.style}
        src={validImageUrl}
        alt="Brand logo"
      />
    );
  };

  const CustomLabelField = ({ schema, uiSchema }) => {
    // console.log("title schema: ", schema)
    // console.log("title ui schema: ", uiSchema)
    if(schema.id === 2 && schema.isEnabled) {
      return (
        <div style={ uiSchema.style }>
          <sup id="super">{schema.label}</sup>
        </div>
      );
    } else if(schema.id === 3 && schema.isEnabled) {
      if(location.pathname.includes(ROUTE_PAGES.SUBSTATION_EXPLORER)) {
        return (
          <div style={{ float: "left", ...uiSchema.style }}>
            <span>{schema.label}</span>
          </div>
        );
      } else {
        return null;
      }
    } else if(schema.id === 1 && schema.isEnabled) {
      return (
        <div style={{ float: "left", ...uiSchema.style }}>
          <span>{schema.label}</span>
        </div>
      );
    } else {
      return null;
    }
  };

  const ButtonWidget = ({ schema, uiSchema }) => {
    // console.log("settings button schema: ", schema)
    // console.log("settings button ui schema style: ", uiSchema.style)

    return (
      <Button
        icon={schema.icon}
        text={schema.text}
        onClick={schema.name == ROUTE_PAGES.SETTINGS ? openSettingsPage : logout}
        shape={schema.shape}
        type={schema.btn_type}
        sizeClass={schema.size_class}
        style={uiSchema.style}
      />
    );
  }

  const CustomPopupWidget = ({ schema, uiSchema, formData }) => {
    // console.log("schema: ", schema)
    // console.log("uiSchema: ", uiSchema)
    // console.log("formData: ", formData)
    const email = JSON.parse(localStorage.getItem("USER_INFO"))?.email
    // console.log("email: ", email)
    const imageUrl = navbarData.buttons.popup.image_url;
    const validImageUrl = isValidUrl(imageUrl) ? imageUrl : avtar; // Fallback to default logo if URL is invalid
    
    return (
      <WithPopup>
        <span style={{ cursor: "pointer" }}>
          {" "}
          <img 
            className="avtar-icon" 
            style={uiSchema.avtar.style}
            src={validImageUrl}
            alt=""
          />
        </span>
        <Popup
          trigger='hover'
          position='bottom right'
          closeOnEscape='true'
          onOpen={() => {
            setIsOpen(true);
            setIsControlledOpen(true);
          }}>
          <div>
            {(
              <div style={uiSchema.email.style}><p>{email}</p></div>
            )}
          </div>
        </Popup>
      </WithPopup>
    );
  };

  return (
    <>
      <div className="row">
        <UserSession />
        <div className="header-container">
          <div className="brand">
          {navbarSchema.properties.brand.properties.logo.isEnabled ? 
          <Form 
            schema={navbarSchema.properties.brand.properties.logo}
            uiSchema={navbarUISchema.brand.logo}
            formData={navbarData.brand.logo}
            validator={validator}
            widgets={{CheckboxWidget: CustomImageWidget}}
            children={[]}
          /> : "" }
            <div className="brand-name">
              <div className="project-name">
                <AppTopNaviDivider />
                <div>
                  <div className="title-name" style={{ display: 'flex', flexDirection: "column"}}>
                    {navbarSchema.properties.brand.properties.title.isEnabled ? 
                    <Form 
                      key={location.pathname}
                      schema={navbarSchema.properties.brand.properties.title} 
                      uiSchema={navbarUISchema.brand.title} validator={validator} 
                      fields={{ StringField: CustomLabelField }} 
                      children={[]}
                    /> : "" }
                  </div>
                  {currentPage.includes(ROUTE_PAGES.SUBSTATION_EXPLORER) ?
                    "" : <Breadcrumb style={{ "width": "600px", "color": "black" }}>
                      <Breadcrumb.Item linkAs={Link} linkProps={{ to: defaultBreadcrumb.path }}>
                        <Icon sizeClass="small" name="abb/home" />

                      </Breadcrumb.Item>
                      &nbsp;

                      {breadcrumbs.map((breadcrumb, index) => (
                        <>
                          &nbsp;{">"} &nbsp;
                          {breadcrumb?.label?.toString().replace("%20", " ")}
                        </>
                      ))}
                    </Breadcrumb>}
                </div>
              </div>
            </div>
          </div>

          <AppTopNavi monochrome={monochrome} allowScroll={true}>
          </AppTopNavi>

          {loggedInUserInfo?.role == "OperationalManager" && navbarSchema.properties.buttons.properties.settings.isEnabled ? 
          <Form 
            schema={navbarSchema.properties.buttons.properties.settings} 
            uiSchema={navbarUISchema.buttons.settings}
            validator={validator} 
            widgets={{ CheckboxWidget: ButtonWidget }}
            children={[]}
          /> : ""}
          {navbarSchema.properties.buttons.properties.logout.isEnabled ?
          <Form 
            schema={navbarSchema.properties.buttons.properties.logout}
            uiSchema={navbarUISchema.buttons.logout}
            validator={validator} 
            widgets={{ CheckboxWidget: ButtonWidget }}
            children={[]}
          /> : ""}
          {navbarSchema.properties.buttons.properties.popup.isEnabled ?
          <div className="profile">
            <Form 
              schema={navbarSchema.properties.buttons.properties.popup}
              uiSchema={navbarUISchema.buttons.popup}
              formData={navbarData.buttons.popup}
              validator={validator} 
              widgets={{ CheckboxWidget: CustomPopupWidget}}
              children={[]}
            />
          </div>
          : ""}
        </div>
      </div>
    </>
  );
}
export default Navbar;
