import React, { useEffect, useContext, useState, useMemo, useCallback } from "react";
import { Datagrid, Spinner } from "@abb/abb-common-ux-react";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import { BASE_URL } from "../../strings";
import { convertUtcToLocalString } from "../../util";
import { getToken } from "../Authentication/get-token";
import columnConfigJSON from "./alarm-noti-columns-config.json"; // Import JSON
import "./alarm-notifications.css"
function AlarmNotificationsData() {
  const { startTS, endTS, settotalNotiRecords } = useContext(RootContext);
  const [alarmNotificationResponse, setAlarmNotificationResponse] = useState([]);
  const [isSpin, setIsSpin] = useState(true);
  const [error, setError] = useState(false);

  const SUBSTATION_ID = useMemo(() => localStorage.getItem("selectedSubstationId"), []);
  // Use column configuration from JSON
  const columnConfig = useMemo(() => columnConfigJSON.columns, []);
  const columns = useMemo(() => columnConfig.filter((col) => col.enabled), [columnConfig]);

  const filteredData = useMemo(() => {
    return alarmNotificationResponse.map((item) => ({
      fields: Object.entries(item.fields)
        .filter(([key]) => columnConfig.some((col) => col.enabled && col.fieldKey === key))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
    }));
  }, [alarmNotificationResponse, columnConfig]);

  const fetchAlarmNoti = useCallback(async () => {
    setIsSpin(true);
    try {
      const response = await axios.get(
        `${BASE_URL}alarm-notification-summary?startDateTime=${startTS}&endDateTime=${endTS}&substationId=${SUBSTATION_ID}`,
        getToken()
      );

      const alarmNotiResp = response?.data?.alarmNotifications || [];
      const formattedData = alarmNotiResp.map((v) => ({
        fields: {
          bay: v?.relayId,
          timeStamp: convertUtcToLocalString(v?.triggeredOn.split(".")[0]),
          description: v?.description,
          priority: v?.priority, // Example field for new column
        },
      }));

      setAlarmNotificationResponse(formattedData);
      settotalNotiRecords(alarmNotiResp.length);
      setError(false);
    } catch (error) {
      setError(true);
    } finally {
      setIsSpin(false);
    }
  }, [startTS, endTS, SUBSTATION_ID, settotalNotiRecords]);

  useEffect(() => {
    fetchAlarmNoti();
  }, [fetchAlarmNoti]);

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          sizeClass="small"
        />
      ) : (
        <>
          {filteredData.length > 0 ? (
            <Datagrid
              className="table-style-alarm-noti"
              data={filteredData}
              columns={columns}
              rowHeight="small"
              enableSorting={true}
              sortOrder={{ field: "timeStamp", desc: true }}
              onSort={(so) => console.log("Sorting Order Changed:", so)}
            />
          ) : (
            <p className="center-text-error">No notifications available</p>
          )}
        </>
      )}
    </>
  );
}

export default AlarmNotificationsData;
