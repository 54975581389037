import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, Icon, WithPopup, Popup, } from "@abb/abb-common-ux-react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { RootContext } from "../../contexts/root-context";
import { BASE_URL } from "../../strings";
import { convertUtcToLocalString } from "../../util";
import NotificationExample from "../Notification/notification";
import { getToken } from "../Authentication/get-token";
import "./sld.css";
import jsonSchema from "./communication-status.json";
import Form from "@rjsf/core";
import validator from '@rjsf/validator-ajv8';
import sldSchema from './sld-schema.json';
import sldUISchema from './sld-uischema.json';

function SLD() {
  const { setSpinner, startTS, isnotify, setisnotify } = useContext(RootContext); //setSpinner is used to show the spinner when the page is loading
  setSpinner(true);
  const [__, setError] = useState(false);
  const [_, setispin] = useState(true);
  const [sldDetails, setSLDDetails] = useState([]);
  const [setrelayHealthy] = useState([]);
  const [setgatewaylist] = useState([]);
  const [setrelayUnHealthy] = useState([]);
  //Hover Popup
  const [isOpen, setIsOpen] = useState(false);
  const [isControlledOpen, setIsControlledOpen] = useState(false);
  const [closeOnEscape] = useState(true);
  const [trigger] = useState("hover");
  const [position] = useState("bottom center right");
  //For PDF file reading
  const [pdfBase64, setPdfBase64] = useState(null);
  const [pdfNotFound, setPdfNotFound] = useState(false);
  const [currectSubId, setCurrectSubId] = useState(localStorage.getItem("selectedSubstationId"))
  const location = useLocation();
  const { isSubstationStatusEnabled, substationStatus, isActiveRelayStatusEnabled, activeRelayStatus } = jsonSchema;
  const { title: substationTitle, options: substationOptions, popUp: substationStatusPopUp } = substationStatus;
  const { title: activeRelayTitle, options: activeRelayOptions, popUp: activeRelayStatusPopUp } = activeRelayStatus;

  useEffect(() => {
    fetchSLDDetails();
  }, [startTS, isOpen, isControlledOpen, currectSubId]);

  const getSelectedSubId = (localsub) => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");

    const urlSubId = pathSegments[pathSegments.length - 1].toString().replace("%20DM", "");
    if (localsub !== urlSubId) {
      setCurrectSubId(urlSubId)
    }
  };

  const filterSLDDetails = useCallback(() => {
    const allGateways = sldDetails?.gatewayList?.data;
    setgatewaylist(allGateways);
    const allHealthyRelays = sldDetails?.relayList?.filter(
      (item) => item.relayHealth === true
    );
    setrelayHealthy(allHealthyRelays);
    const allUnhealthyRelays = sldDetails?.relayList?.filter(
      (item) => item.relayHealth === false
    );
    setrelayUnHealthy(allUnhealthyRelays);
  }, [currectSubId]);

  const fetchSLD = useCallback(() => {
    fetch(BASE_URL + `sld-preview?substationId=${currectSubId}.pdf`, getToken())
      .then((response) => {

        if (response.status === 400) {
          console.error("PDF not found for substation ID:", currectSubId);
          setPdfNotFound(true);
          return;
        }
        return response.text();
      })
      .then((data) => {

        setPdfBase64(data);
      })
      .catch((error) => {
        setPdfNotFound(true);
        console.error("Error fetching PDF content:", error);
      });
  }, [currectSubId]);

  const fetchSLDDetails = useCallback(async () => {
    setispin(true);
    getSelectedSubId(currectSubId);
    try {
      const response = await axios.get(
        BASE_URL + `sld-metrics?substationId=${currectSubId}`, getToken()
      );
      let fetchedSLDDetailsInfo = response.data;
      setSLDDetails(fetchedSLDDetailsInfo);

      filterSLDDetails();
      setError(false);
      setispin(false);
    } catch (error) {
      setError(true);
      setispin(false);
    } finally {
      fetchSLD();
    }
  }, [currectSubId, filterSLDDetails, fetchSLD]);

  const handleFileChange = useCallback(async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setisnotify("sld-too-large");
        console.error("File size exceeds 5MB. Please choose a smaller file.");
        return;
      }
      if (file.type !== "application/pdf") {
        setisnotify("sld-invalid-format");
        console.error("Invalid file format. Please choose a PDF file.");
        return;
      }
      const reader = new FileReader();
      const headers = {
        'Content-Type': 'application/pdf',
        'Authorization': getToken().headers.Authorization, // Include the token in the Authorization header
      };

      reader.onload = (event) => {
        const base64 = event.target.result.split(",")[1];
        const url = BASE_URL + `sld-upload?substationId=${currectSubId}.pdf`;
        fetch(url, {
          method: "POST",
          body: base64,
          headers: headers
        })
          .then((response) => {
            if (response.ok) {
              setError(false);
              setisnotify("sld-success");
              fetchSLD();
              fetchSLDDetails();
            } else {
              setisnotify("sld-failure");
              console.error("Failed to upload SLD. Please try again later");
              setError(true);
            }
          })
          .catch((error) => {
            setisnotify("sld-failure");

            setError(true);
          });
      };
      reader.readAsDataURL(file);
    }
  }, [currectSubId, fetchSLD, fetchSLDDetails]);

  const timeAgo = useCallback((date) => {
    const currentDate = new Date();
    const previousDate = new Date(date);
    // Calculate the difference in milliseconds
    const timeDifference = currentDate - previousDate;
    // Calculate the difference in seconds, minutes, hours, days, and months
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    if (months > 0) {
      return months === 1 ? `${months} month ago` : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? `${days} day ago` : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
    } else {
      return 'Just now';
    }
  }, []);
  const sldLastModified = sldDetails?.sldLastModified ? sldSchema.properties.Last_Updated.label
    + timeAgo(sldDetails?.sldLastModified) + " " : "";

  const uploadSingleLineDiagramEnabled = JSON.parse(localStorage.getItem("USER_INFO"))?.permissions?.uploadSingleLineDiagram;

  const CustomLabelField = ({ schema, uiSchema }) => {
    // console.log("title schema: ", schema)
    // console.log("title ui schema: ", uiSchema)
    return (
      <div style={uiSchema.style}>
        <span className="sld-chart-title">{schema.label}</span>
      </div>
    );
  };

  const Link_Widget = ({ uiSchema }) => {
    // console.log("uiSchema: ", uiSchema)
    return (
      <div>
        <Link style={{ float: "right", ...uiSchema.style }} href='' onClick={() => { window.open("#/sld-preview", "_blank") }} >
          <Icon
            className="open-window"
            name={'abb/' + "open-in-new-window"}
            sizeClass="medium"
          />
        </Link>
      </div>
    );
  };

  const Substation_Name_Label = ({ schema, uiSchema }) => {
    return (
      <div>
        <b style={uiSchema.sub_name.style}> {schema.sub_name} </b>
        <span style={uiSchema.sub_value.style}>{currectSubId}</span>
      </div>
    );
  }

  const File_Upload_Widget = ({ schema, uiSchema }) => {
    return (
      <>
        <label id="change-sld-link" htmlFor="inputField">
          <Icon name={uiSchema.icon_name} className="change-sld-link-icon" />
          <span style={uiSchema.style}>{pdfNotFound ? schema.upload_sld_label : schema.change_sld_label}</span> </label>
        <input
          disabled={!uploadSingleLineDiagramEnabled}
          accept=".pdf"
          onChange={handleFileChange}
          type="file"
          id="inputField"
          style={{ display: "none" }}
        />
      </>
    );
  };

  let sldMessage = pdfNotFound ? "No SLD found. Please upload a file" : "Loading SLD...";

  return (
    <>
      {isnotify === "sld-success" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isnotify === "sld-failure" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isnotify === "sld-too-large" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isnotify === "sld-invalid-format" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      <div className="sld-container" style={{ ...sldUISchema.card.style }}>
        {sldSchema.properties.card.is_header_enabled ?
          <>
            <div className="sld-container-title">
              {sldSchema.properties.title.isEnabled ?
                <div style={{ flex: 1, textAlign: 'left' }}>
                  <Form
                    schema={sldSchema.properties.title}
                    uiSchema={sldUISchema.title} validator={validator}
                    fields={{ StringField: CustomLabelField }}
                    children={[]}
                  />
                </div> : ""}
              {sldSchema.properties.Link.isEnabled ?
                <div style={{ flex: 1, textAlign: 'right' }}>
                  <Form
                    schema={sldSchema.properties.Link}
                    uiSchema={sldUISchema.Link} validator={validator}
                    widgets={{ CheckboxWidget: Link_Widget }}
                    children={[]}
                  />
                </div> : ""}
            </div>
            <hr />
          </> : ""}
        <table id="sld-info-table">
          <tr className="sld-top-info">
            <td className="first-col">
              {sldSchema.properties.Substation_Name.isEnabled ?
                <Form
                  key={currectSubId}
                  schema={sldSchema.properties.Substation_Name}
                  uiSchema={sldUISchema.Substation_Name} validator={validator}
                  fields={{ StringField: Substation_Name_Label }}
                  children={[]}
                /> : ""}
            </td>
            <td className="second-col">
              <div style={{ display: "flex" }}>
                <div style={{ textAlign: 'left', marginRight: "5px" }}>
                  {sldSchema.properties.Last_Updated.isEnabled ?
                    <span style={sldUISchema.Last_Updated.style}>{sldLastModified}</span>
                    : ""}
                </div>
                <div style={{ textAlign: 'right' }}>
                  <Form
                    schema={sldSchema.properties.Upload_SLD}
                    uiSchema={sldUISchema.Upload_SLD} validator={validator}
                    widgets={{ CheckboxWidget: File_Upload_Widget }}
                    children={[]}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr className="sld-top-info">
            {isSubstationStatusEnabled && (
              <td className="first-col sld-common-class">
                <span className="sld-captions">
                  {substationTitle}
                </span>
                <div >
                  <span>
                    <span>{sldDetails?.substationCommunicationStatus?.normal?.length}</span>
                    <Icon name={substationOptions[0].icon.name} className={substationOptions[0].icon.className} />

                    <WithPopup >
                      <span style={{ cursor: "pointer" }}>
                        {substationOptions[0].label}
                        {" "}
                      </span>

                      <Popup
                        trigger={trigger}
                        position={position}
                        closeOnLostFocus={false}

                        closeOnEscape={closeOnEscape}
                        onOpen={() => {
                          setIsOpen(true);
                          setIsControlledOpen(true);
                        }}
                      >{sldDetails?.substationCommunicationStatus?.normal?.length === 0 ? <div>No gateway available</div> :
                        <div className="gateway-status-popup-container">
                          <span className="sld-captions">
                            {substationStatusPopUp.title}
                          </span>
                          <br />
                          {sldDetails?.substationCommunicationStatus?.normal?.map((item, index) => (
                            <p key={item?.gatewayId}>
                              <span className="sld-captions">
                                {substationStatusPopUp.gatewayId} {" "}{item?.gatewayId}{"  "} ({item?.relays} relays)
                                {item?.substationCommunicationStatus}
                              </span>
                              <br />
                              <span>
                                {substationStatusPopUp.lastUpdateOn}{" "}
                                {convertUtcToLocalString(item?.sentOn.replace('T', ' ').substring(0, 16))}
                                {" "}
                              </span>
                            </p>
                          ))}
                        </div>
                        }
                      </Popup>

                    </WithPopup>
                  </span>&nbsp;&nbsp;
                  <span>
                    <span>{sldDetails?.substationCommunicationStatus?.waitingForConnection?.length}</span>
                    <Icon name={substationOptions[1].icon.name} className={substationOptions[1].icon.className} />
                    <WithPopup>
                      <span style={{ cursor: "pointer" }}>
                        {substationOptions[1].label}
                      </span>
                      <Popup
                        trigger={trigger}
                        position={position}
                        closeOnLostFocus={false}

                        closeOnEscape={closeOnEscape}
                        onOpen={() => {
                          setIsOpen(true);
                          setIsControlledOpen(true);
                        }}
                      >{sldDetails?.substationCommunicationStatus?.waitingForConnection?.length === 0 ? <div>No gateway available</div> :
                        <div>
                          <span className="sld-captions">
                            {substationStatusPopUp.title}
                          </span>
                          <br />
                          {sldDetails?.substationCommunicationStatus?.waitingForConnection?.map((item, index) => (
                            <p key={item?.gatewayId}>
                              <span className="sld-captions">
                                {substationStatusPopUp.gatewayId} {" "}{item?.gatewayId}{"  "} ({item?.relays} relays)
                                {item?.substationCommunicationStatus}
                              </span>
                              <br />
                              <span>
                                {substationStatusPopUp.lastUpdateOn}{" "}
                                {convertUtcToLocalString(item?.sentOn.replace('T', ' ').substring(0, 16))}
                                {" "}
                              </span>
                            </p>
                          ))}
                        </div>
                        }
                      </Popup>
                    </WithPopup>
                  </span>&nbsp;&nbsp;
                  <span>
                    <span>{sldDetails?.substationCommunicationStatus?.connectionLost?.length}</span>
                    <Icon name={substationOptions[2].icon.name} className={substationOptions[2].icon.className} />
                    <WithPopup>
                      <span style={{ cursor: "pointer" }}>
                        {substationOptions[2].label}
                      </span>
                      <Popup
                        trigger={trigger}
                        position={position}
                        closeOnLostFocus={false}
                        closeOnEscape={closeOnEscape}
                        onOpen={() => {
                          setIsOpen(true);
                          setIsControlledOpen(true);
                        }}
                      >{sldDetails?.substationCommunicationStatus?.connectionLost?.length === 0 ? <div>No gateway available</div> :
                        <div>
                          <span className="sld-captions">
                            {substationStatusPopUp.title}
                          </span>
                          <br />
                          {sldDetails?.substationCommunicationStatus?.connectionLost?.map((item, index) => (
                            <p key={item?.gatewayId || index}>
                              <span className="sld-captions">
                                {substationStatusPopUp.gatewayId} {" "}{item?.gatewayId}{"  "} ({item?.relays} relays)
                                {item?.substationCommunicationStatus}
                              </span>
                              <br />
                              <span>
                                {substationStatusPopUp.lastUpdateOn}{" "}
                                {convertUtcToLocalString(item?.sentOn.replace('T', ' ').substring(0, 16))}
                                {" "}
                              </span>
                            </p>
                          ))}
                        </div>
                        }
                      </Popup>
                    </WithPopup>
                  </span>
                </div>
              </td>
            )}
            {isActiveRelayStatusEnabled && (
              <td className="second-col">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span className="sld-body">
                    <span className="sld-captions">
                      {activeRelayTitle}{" "}
                    </span>
                    <div style={{ display: "flex" }}>
                      <div style={{ display: "flex" }}>
                        <span>{sldDetails?.relayStatus?.Online.length}</span>
                        <span>
                          <WithPopup>
                            <span style={{ cursor: "pointer" }}>
                              <Icon
                                name={activeRelayOptions[0].icon.name}
                                className={activeRelayOptions[0].icon.className}
                              />
                              {activeRelayOptions[0].label}
                            </span>
                            <Popup
                              trigger={trigger}
                              position={position}
                              closeOnEscape={closeOnEscape}
                              onOpen={() => {
                                setIsOpen(true);
                                setIsControlledOpen(true);
                              }}
                            >
                              {sldDetails?.relayStatus?.Online.length > 0 ? <div
                                style={{
                                  font: "5px",
                                  height: "100px",
                                  overflowY: "scroll",
                                  scrollbarWidth: "2px",
                                }}
                              >
                                <p className="relay-title">
                                  {activeRelayStatusPopUp.onlineRelays} ({sldDetails?.relayStatus?.Online.length}):
                                </p>
                                {sldDetails?.relayStatus?.Online?.map((item, index) => (
                                  <p key={item?.assetName}>
                                    <span className="relay-id">
                                      {activeRelayStatusPopUp.relayId} {item?.assetName} (GW ID : {item?.gatewayId})
                                    </span>
                                    <br />
                                    <span className="relay-time-update">
                                      {activeRelayStatusPopUp.lastUpdateOn}{" "}
                                      <span>
                                        {convertUtcToLocalString(item?.sentOn?.replace('T', ' ').substring(0, 16))}
                                      </span>{" "}
                                    </span>
                                    <br />
                                  </p>
                                ))}
                              </div> : <div  >No active healthy online available</div>
                              }
                            </Popup>
                          </WithPopup>
                        </span>
                        <span>
                          <span>
                            &nbsp;&nbsp;{sldDetails?.relayStatus?.Offline.length}
                          </span>
                          <span>
                            <WithPopup>
                              <span style={{ cursor: "pointer" }}>
                                <Icon name={activeRelayOptions[1].icon.name} className={activeRelayOptions[1].icon.className} />
                                {activeRelayOptions[1].label}
                              </span>
                              <Popup
                                trigger={trigger}
                                position={position}
                                closeOnEscape={closeOnEscape}
                                onOpen={() => {
                                  setIsOpen(true);
                                  setIsControlledOpen(true);
                                }}
                              >
                                {sldDetails?.relayStatus?.Offline.length > 0 ?
                                  <div
                                    style={{
                                      font: "5px",
                                      height: "100px",
                                      overflowY: "scroll",
                                      scrollbarWidth: "2px",
                                    }}
                                  >
                                    <p className="relay-title">
                                      {activeRelayStatusPopUp.offlineRelays} (
                                      {sldDetails?.relayStatus?.Offline.length}):
                                    </p>

                                    {sldDetails?.relayStatus?.Offline?.map((item, index) => (
                                      <p key={item?.assetName}>
                                        <span className="relay-id">
                                          {activeRelayStatusPopUp.relayId} {item?.assetName} (GW ID : {item?.gatewayId})
                                        </span>
                                        <br />
                                        <span className="relay-time-update">
                                          {activeRelayStatusPopUp.lastUpdateOn}{" "}
                                          <span>
                                            {convertUtcToLocalString(item?.sentOn?.replace(/:\d{2}\.\d+/g, ""))}
                                          </span>{" "}
                                        </span>
                                        <br />
                                      </p>
                                    ))}
                                  </div> : <div  >No active offline relays available</div>
                                }
                              </Popup>
                            </WithPopup>
                          </span>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </td>
            )}
          </tr>
        </table>
        {pdfBase64 ? <iframe
          title="sld-preview"
          width={"100%"}
          height={"100%"}
          className="sld-iframe"
          type="application/pdf"
          loading="eager"
          src={`data:application/pdf;base64,${pdfBase64}`}
        />
          : sldMessage}
      </div >
    </>
  );
}
export default React.memo(SLD);
