//Import library
import Ajv from "ajv";

const moment = require("moment");
export function getDefaultTimeStamp(minutes) {
  const DEFAULT_MINUTES_IN_A_DAY = 1440; // Default value for minutes if not provided

  // Use the provided minutes or fallback to the default
  const effectiveMinutes = !isNaN(minutes) ? minutes : DEFAULT_MINUTES_IN_A_DAY;

  // Get the current UTC time
  const utcTime = moment.utc();
  const utcFormatTime = utcTime.format("YYYY-MM-DDTHH:mm");

  // Subtract the specified minutes from the UTC time
  const pastTime = utcTime.clone().subtract(effectiveMinutes, "minutes");
  const pastTimeFormat = pastTime.format("YYYY-MM-DDTHH:mm");

  // Create and return the timestamps object
  return {
    endDate: utcFormatTime,
    startDate: pastTimeFormat,
  };
}
export default function getTimestamps(minutes) {
  if (!isNaN(minutes)) {
    // Convert the system time to UTC time
    let utcTime = moment.utc();
    const utcFormatTime = utcTime.format("YYYY-MM-DDTHH:mm");

    // Subtract 60 minutes from the UTC time
    const pastTime = utcTime.subtract(minutes, "minutes");
    // Format the result in YYYY-MM-DDTHH:mm format
    const pastTimeFormat = pastTime.format("YYYY-MM-DDTHH:mm");
    let timestamps = {
      endDate: utcFormatTime,
      startDate: pastTimeFormat,
    };
    return timestamps;
  } else {
    return;
  }
}

export function convertUtcToLocalString(utcDateString) {
  if (utcDateString) {
    const utcDate = new Date(utcDateString + " UTC");
    let localISOString = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    ).toISOString();
    return localISOString.replace("T", " ").substring(0, 16);
  }
  return "NULL";
}
export function convertUtcToLocalStringDR(drfaulttime) {
  if (drfaulttime) {
    const utcDate = new Date(drfaulttime + " UTC");
    // Define options for formatting
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Use 24-hour format
    };

    // Convert UTC time to local time in the specified format
    const localTimeString = utcDate.toLocaleString("en-US", options);

    return localTimeString;
  }
  return "NULL";
}

export function getAlarmSummaryLegendsColor(label) {
  switch (label) {
    case "Earth Fault": {
      return "#CC0815";
      break;
    }
    case "Overcurrent Fault": {
      return "#F2AC66";
      break;
    }
    case "Medium": {
      return "#FFD800";
      break;
    }
    case "Informational": {
      return "#0052FF";
      break;
    }
    case "Ok": {
      return "#51A34B";
      break;
    }
    default: {
      break;
    }
  }
  return;
}
export const encryptData = (data) => {
  try {
    const encryptedData = window.btoa(JSON.stringify(data));
    return encryptedData;
  } catch (error) {
    console.error("Encryption failed:", error);
    return null;
  }
};
export const decryptData = (encryptedData) => {
  try {
    const decryptedData = window.atob(encryptedData);
    return decryptedData;
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

// Initialization of Ajv instance
let ajvInstance = null;
const getAjv = () => {
  if (!ajvInstance) {
    ajvInstance = new Ajv({
      strict: false,
      removeAdditional: true,
      allErrors: true,
    });
  }
  return ajvInstance;
};

/**
Validates JSON data against a schema with optional error filtering.
@param {Object} schema - The JSON schema.
@param {Array<string>} ignoredProperties - List of property names to ignore errors for.
@returns {Object} - Validation result with success status and error messages.
*/
export const validateJsonSchema = (schema, ignoredProperties = []) => {
  const ajv = getAjv();
  const validate = ajv.compile(schema);
  const isValid = validate(schema);

  if (isValid) {
    console.log("Validation succeeded!");
    return { success: true };
  }

  // Filter errors based on ignored properties and custom errors
  const filteredErrors = validate.errors.filter((err) => {
    const isIgnored = ignoredProperties.some((prop) =>
      err.message.includes(`'${prop}'`)
    );
    return !isIgnored;
  });

  const errorMessages = filteredErrors.map(
    (err) => `Error at ${err.instancePath}: ${err.message}`
  );

  if (errorMessages.length > 0) {
    console.error("Validation Errors:", errorMessages);
    return { success: false, errors: errorMessages };
  }

  console.log("Validation succeeded with ignored errors.");
  return { success: true, ignoredErrors: validate.errors };
};

export const isJsonValidSchema = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch {
    return false;
  }
};

//Validation of forgot password

export const validateJson = (jsonData, jsonSchema) => {
  const ajv = new Ajv();
  const validate = ajv.compile(jsonSchema);
  const isDataValid = validate(jsonData);

  if (isDataValid) {
    console.log('Success! data matches the predefined schema.');
  } else {
    const errorMessages = validate.errors.map((err) => {
      return `Error at ${err.instancePath}: ${err.message}`;
    });
    console.error('Validation Errors:', errorMessages);
  }
};

export const isJsonValid = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
};
