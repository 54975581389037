import React, { useContext,useEffect } from 'react';
import jsonSchema from "./notification.json";

import { NotificationContainer } from "@abb/abb-common-ux-react";
import { RootContext } from "../../contexts/root-context";
import "./notification.css";
import {  validateJsonSchema, isJsonValidSchema } from "../../util";
import PropTypes from 'prop-types';

export default function NotificationExample(props) {
    const { drwidgetConfig } = useContext(RootContext);
    const { notitype } = props;
   
  useEffect(() => {
    const jsonString = JSON.stringify(jsonSchema);
    if (isJsonValidSchema(jsonString)) {
      console.log('JSON being validated: notification', jsonSchema);
      validateJsonSchema(jsonSchema); // Pass ignored properties
    } else {
      console.error('Invalid JSON syntax: Missing braces or commas.');
    }
  }, []);
// Add propTypes validation
NotificationExample.propTypes = {
    notitype: PropTypes.string.isRequired, // or adjust type based on your requirement
  };

    const createNotification = ({ id, type, discreet, severity, timeout, text, customStyle = {} }) => ({
        id,
        type,
        discreet,
        severity,
        timeout,
        text,
        customStyle,
    });

    // Notification Data
    const notificationDataMap = {
        true: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "info",
                timeout: 3000,
                text: `Please wait while we open the file in wavewin`,
            }),
        ],
        error: [
            createNotification({
                id: 2,
                type: 'banner',
                discreet: 2,
                severity: "warn",
                timeout: 3000,
                text: `Error while generating URL.`,
            }),
        ],
        updatePassword: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "success",
                timeout: 5000,
                text: `Your password has been changed successfully.`,
            }),
        ],
        "dr-acked": [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "success",
                timeout: 3000,
                text: `DR File ${drwidgetConfig[0]?.currentFile} Successfully Acknowledged.`,
            }),
        ],
        genrateReport: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "info",
                timeout: 3000,
                text: `Please wait while we generate the report. You will be notified via email when the report is ready.`,
            }),
        ],
        nodr: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "alarm",
                timeout: 3000,
                text: `No DR files available for the given inputs`,
            }),
        ],
        hr:  [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "success",
                timeout: 3000,
                text: `Report sent to your mail`,
            }),
        ],
        subsuccess:[
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "success",
                timeout: 3000,
                text: `Substations details updated successfully.`,
            }),
        ],
        subfaliure: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "alarm",
                timeout: 3000,
                text: `Failed to upload file content.`,
            }),
        ],
        subDelete: jsonSchema.properties.subdeletenotifications.enable.default
            ? [{
                id: 1,
                type: jsonSchema.properties.subdeletenotifications.items.properties.type.default,
                discreet: 1,
                severity: jsonSchema.properties.subdeletenotifications.items.properties.severity.default,
                timeout: jsonSchema.properties.subdeletenotifications.items.properties.timeout.default,
                text: jsonSchema.properties.subdeletenotifications.items.properties.text.default
                
            }]
            : [],
        userRemove: jsonSchema.properties.userremovenotifications.enable.default
            ? [{
                id: 1,
                type: jsonSchema.properties.userremovenotifications.items.properties.type.default,
                discreet: 1,
                severity: jsonSchema.properties.userremovenotifications.items.properties.severity.default,
                timeout: jsonSchema.properties.userremovenotifications.items.properties.timeout.default,
                text: jsonSchema.properties.userremovenotifications.items.properties.text.default
            }]
            : [],
        sldsuccess: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "success",
                timeout: 3000,
                text: `File successfully uploaded.`,
            }),
        ],
        sldfaliure: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "alarm",
                timeout: 3000,
                text: `Failed to upload.`,
            }),
        ],
        sldlarge: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "alarm",
                timeout: 3000,
                text: `File size exceeds 5MB. Please choose a smaller file.`,
            }),
        ],
        sldInvalid: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "alarm",
                timeout: 3000,
                text: `Invalid file format. Please choose a PDF file.`,
            }),
        ],
        noSubAssign: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "info",
                text: `No operation area assigned, Please contact Ops. Manager.`,
            }),
        ],
        invalidSubstation: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "alarm",
                text: `You are not assigned to this substation.`,
            }),
        ],
        loadingSub: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "info",
                text: `Please wait while we fetch the substations from the assigned operation areas.`,
            }),
        ],
        postLogout: [
            createNotification({
                id: 1,
                type: 'banner',
                discreet: 1,
                severity: "info",
                timeout: 5000,
                text: `User has been logged out, please wait while redirecting to login.`,
            }),
        ],
    };

    const positionOverrides = {
        "no-sub-assign": { top: '50px', right: '-5px' },
        "loading-substations": { top: '125px', right: '-5px' },
        "go-back-login": { top: '50px', right: '-5px' },
        "unassigned-substation": { top: '50px', right: '-5px' },
    };

    const notifications = notificationDataMap[notitype];
    const positionStyle = positionOverrides[notitype] || { position: 'absolute', top: '100px', right: '0px', zIndex: 9, textAlign: 'left' };

    return (
        <>
            {notifications && <NotificationContainer notifications={notifications.notifications} style={positionStyle} />}
        </>
    );
}