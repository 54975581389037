import React, { useEffect, useState, useCallback } from "react";
import { useRbac } from "./RbacContext";
import jsonSchema from "./edit-user-schema.json";
import uiSchema from "./edit-user-uischema.json";
import { validateJsonSchema, isJsonValidSchema } from "../../util";
 
import {
  Dropdown,
  DropdownOption,
  Icon,
  Input,
  Button,
  ToggleSwitch,
  MessageDialog
} from "@abb/abb-common-ux-react";
import { BASE_URL, MESSAGES } from "../../strings";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../components/Authentication/get-token";
import "./edit-user.css";
 
const EditUser = () => {
  const commonFlags = {
    clearable: true,
    searchable: true,
    clearOnEscape: true,
    sizeClass: "small",
  };
 
  const [currentUrl] = useState(window.location.hash);
  const emailRegex =
    /\/edit-user\/([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
  const match = emailRegex.exec(currentUrl);
  const currentUserEmailId = match ? match[1] : null;
  //Switch Inputs
  const [updateUserValue, setUpdateUserValue] = React.useState(false);
  const [accessSubMap, setAccessSubMap] = React.useState(false);
  const [accessSubExp, setAccessSubExp] = React.useState(false);
  const [updateCaesMLFeedback, setUpdateCaesMLFeedback] = React.useState(false);
  const [singleLineValue, setSingleLineValue] = React.useState(false);
  const [acknowledgeValue, setAcknowledgeValue] = React.useState(false);
  const [mobileNotificationValue, setMobileNotificationValue] =
    React.useState(false);
  const [escalationValue, setEscalationValue] = React.useState(false);
  //Dropdowns
  const [substationTableData, setSubstationTableData] = React.useState([]);
  const [opsPlace, setOpsPlace] = React.useState([]);
  const [opsSelectedPlace, setOpsSelectedPlace] = React.useState([]);
  const [isSetFetchOps, setIsSetFetchOps] = useState(false);
 
  //API Set vars
  const [editUserDetails, setEditUserDetails] = React.useState();
  const [error, setError] = useState(false);
  const [filteredSubstations, setFilteredSubstations] = useState([]);
  const [isSaveClick, setIsSaveClick] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const { rbacData, fetchRbacData, setRbacData } = useRbac();
 
  //Save button in form
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  //Dropdown
  const [dpEdited, setDpEdited] = useState(false);
 
  const formData = {
    name: `${editUserDetails?.givenName} ${editUserDetails?.familyName}`,
    email: editUserDetails?.email,
    currentLocation: editUserDetails?.address,
    contactNumber: editUserDetails?.phoneNumber,
    activeStatus: editUserDetails?.activeStatus || false,
    role: editUserDetails?.role || "",
  };
 
  //Navigation
  const navigate = useNavigate();
  //validation of JSON
  useEffect(() => {
    const jsonString = JSON.stringify(jsonSchema);
 
    if (isJsonValidSchema(jsonString)) {
      console.log("JSON being validated:", jsonSchema);
      validateJsonSchema(jsonSchema);
    } else {
      setError("Invalid JSON syntax: Missing braces or commas.");
    }
  }, []);
  //form inputs
  // get from local storage and set to state from rbacManagerUsersList instead of USER_INFO
  // to support other users permissons updation on save
  let localManagerUsersList;
  try {
    const data = localStorage.getItem("rbacManagerUsersList");
    localManagerUsersList = data ? JSON.parse(data) : [];
  } catch (error) {
    console.error("Error parsing rbacManagerUsersList:", error);
    localManagerUsersList = []; // Default to an empty array
  }
 
  const currentUser = localManagerUsersList.find(
    (user) => user.email === currentUserEmailId
  );
 
  const getperimissions = currentUser.permissions;
  const blngetperimissions = parsePermissions(getperimissions);
  function parsePermissions(permissions) {
    return Object.keys(permissions).reduce((acc, key) => {
      acc[key] = JSON.parse(permissions[key]);
      return acc;
    }, {});
  }
  const [options, setOptions] = useState(blngetperimissions);
  useEffect(() => {
    const haveOptionsChanged = Object.keys(options).some(
      (key) => options[key] !== blngetperimissions[key]
    );
    setIsSaveEnabled(dpEdited || haveOptionsChanged);
  }, [dpEdited, options]);
 
  useEffect(() => {}, [accessSubExp]);
  const goToSettings = () => {
    navigate("../settings");
  };
 
  const goToSettingsFromSave = useCallback(async () => {
    if (!rbacData) {
      await fetchRbacData();
    }
    navigate("../../settings");
  }, [rbacData, fetchRbacData, navigate]);
 
  useEffect(() => {
    if (!rbacData) {
      const localData = localStorage.getItem("rbacManagerUsersList");
      if (localData) {
        setRbacData(JSON.parse(localData));
      }
    }
  }, [rbacData, setRbacData]);
 
  const fetchoperationAreasData = async () => {
    let baseURL = BASE_URL + `substation-details`;
    try {
      const response = await axios.get(baseURL, getToken());
 
      let duplicateOperationAreas = [];
      response?.data.forEach((element) => {
        duplicateOperationAreas.push(element?.operationArea);
      });
      let uniqueoperationAreas = [...new Set(duplicateOperationAreas)];
      setSubstationTableData(response?.data);
      setOpsPlace(uniqueoperationAreas);
    } catch (error) {
      console.error(
        "Error fetching operation areas data:",
        error.message || error
      );
    }
  };
 
  function filterAndExtract(substations, filterAreas) {
    const filteredSubstations = substations
      .filter((sub) => filterAreas.includes(sub.operationArea))
      .map((sub) => ({ substationId: sub.substationId, name: sub.name }));
 
    return filteredSubstations;
  }
  const getSubstations = (substations, opsArea) => {
    let selectedops = [];
    opsArea?.forEach((element) => {
      selectedops.push(element?.value);
    });
    const filteredSubstations = filterAndExtract(substations, selectedops);
    setFilteredSubstations(filteredSubstations);
  };
 
  useEffect(() => {
    fetchEditUserData();
  }, [isSetFetchOps]);
 
  const fetchEditUserData = async () => {
    try {
      const response = await axios.get(BASE_URL + `rbac-get`, getToken());
      localStorage.setItem(
        "rbacManagerUsersList",
        JSON.stringify(response.data)
      );
      const localManagerUsersList = JSON.parse(
        localStorage.getItem("rbacManagerUsersList")
      );
      const currentUser = localManagerUsersList.find(
        (user) => user.email === currentUserEmailId
      );
      let fetchdetails = currentUser;
      await fetchoperationAreasData();
      setEditUserDetails(fetchdetails);
      const usersFetchedOpsarea = fetchdetails?.operationAreas;
      if (!usersFetchedOpsarea.includes("")) {
        const formatedOpsAreas = usersFetchedOpsarea.map((area) => ({
          value: area,
          label: area,
          isNew: false,
        }));
        setOpsSelectedPlace(formatedOpsAreas);
        getSubstations(substationTableData, formatedOpsAreas);
      }
      setIsSetFetchOps(true);
      setError(false);
      const userPermission = fetchdetails?.permissions;
      setUpdateUserValue(JSON.parse(userPermission?.userManagement));
      setAccessSubExp(JSON.parse(userPermission?.substationExplorer));
      setAccessSubMap(JSON.parse(userPermission?.addSubstationMapping));
      setSingleLineValue(JSON.parse(userPermission?.uploadSingleLineDiagram));
      setUpdateCaesMLFeedback(JSON.parse(userPermission?.caesMLFeedback));
      setAcknowledgeValue(
        JSON.parse(userPermission?.disturbanceRecordAcknowledgment)
      );
      setMobileNotificationValue(
        JSON.parse(userPermission?.disturbanceRecordMobileNotification)
      );
      setEscalationValue(
        JSON.parse(userPermission?.disturbanceRecordEscalationNotification)
      );
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };
 
  const permissions = {
    userManagement: updateUserValue,
    substationExplorer: accessSubExp,
    addSubstationMapping: accessSubMap,
    uploadSingleLineDiagram: singleLineValue,
    disturbanceRecordAcknowledgment: acknowledgeValue,
    disturbanceRecordMobileNotification: mobileNotificationValue,
    disturbanceRecordEscalationNotification: escalationValue,
    caesMLFeedback: updateCaesMLFeedback,
  };
 
  // Create the final JSON object
  const SaveOrUpdateUserDetails = () => {
    // Validation: Check if all required fields are populated
    if (!formData.name || !formData.email) {
      setError(true);
      return;
    }
    const updatedUserInfoAsBody = {
      ...editUserDetails,
      operationAreas: opsSelectedPlace?.map((item) => item?.value),
      substations: filteredSubstations?.map((item) => item.name),
      permissions: permissions,
    };
 
    const existingLocalStorageUserInfo = JSON.parse(
      localStorage.getItem("USER_INFO")
    );
    if (existingLocalStorageUserInfo?.email === currentUserEmailId) {
      const updatedLocalStorageUserInfo = {
        ...existingLocalStorageUserInfo,
        operationAreas: opsSelectedPlace?.map((item) => item?.value),
        substations: filteredSubstations?.map((item) => item.name),
        permissions: permissions,
      };
      localStorage.setItem(
        "USER_INFO",
        JSON.stringify(updatedLocalStorageUserInfo)
      );
    } else {
      const rbacManagerUsersList =
        JSON.parse(localStorage.getItem("rbacManagerUsersList")) || [];
      const updatedRbacManagerUsersList = rbacManagerUsersList.map((user) =>
        user.email === currentUserEmailId
          ? {
              ...user,
              operationAreas: opsSelectedPlace?.map((item) => item?.value),
              substations: filteredSubstations?.map((item) => item.name),
              permissions: permissions,
            }
          : user
      );
      localStorage.setItem(
        "rbacManagerUsersList",
        JSON.stringify(updatedRbacManagerUsersList)
      );
    }
    const saveUrl = BASE_URL + `rbac-put`;
 
    try {
      axios.put(saveUrl, updatedUserInfoAsBody, getToken());
      setIsSaveClick(false);
      setIsSaved(true);
      fetchEditUserData();
      // fetch entire list and set to localhistory
    } catch (error) {
      setIsSaveClick(false);
      console.error(error);
      setError(true);
    }
  };
 
  function handleSaveUserInfo() {
    setIsSaveClick(true);
  }
 
  const userRole = editUserDetails?.role;
 
  return (
    <>
      {isSaveClick ? (
        <div className="overlay-dr">
          <div className="dialog-containe-edit-user">
            {" "}
            <MessageDialog
              title={MESSAGES.CONFIRM_USER_TITLE}
              message={MESSAGES.CONFIRM_USER_BODY}
              className="save-confirm-dialog-container"
              buttons="yesno"
              icon="abb/settings"
              onExit={(v) => setIsSaveClick(false)}
              onYes={() => {
                SaveOrUpdateUserDetails();
                goToSettingsFromSave(); // Navigate after saving
              }}
              onNo={() => setIsSaveClick(false)}
            />
          </div>{" "}
        </div>
      ) : (
        ""
      )}
      <div className="edit-user-container">
      <button
  style={{ cursor: "pointer", background: "none", border: "none", padding: 0 }}
  onClick={goToSettings}
  aria-label="Go back to settings"
>
  <span>
    <Icon sizeClass="small" name="abb/left-arrow" />
  </span>
  {"  "}
  <span>Back</span>
</button>
 
        {jsonSchema.enabled && (
          <p className="edit-user-header">
            {jsonSchema.title} {editUserDetails?.givenName}{" "}
            {editUserDetails?.familyName}
          </p>
        )}
        {jsonSchema.properties.enabled && (
          <p className="edit-user-sub-header">{jsonSchema.properties.title.title}</p>
        )}
        <table id="edit-user-table">
          <tr>
            {jsonSchema.properties.name.enabled && (
              <td>
                <Input
                  label={jsonSchema.properties.name.title}
                  sizeClass={uiSchema.name["ui:options"].sizeClass}
                  value={formData.name}
                  style={uiSchema.name["ui:options"].style}
                />
              </td>
            )}
            {jsonSchema.properties.email.enabled && (
              <td>
                <Input
                  label={jsonSchema.properties.email.title}
                  sizeClass={uiSchema.email["ui:options"].sizeClass}
                  value={formData.email}
                  style={uiSchema.email["ui:options"].style}
                />
              </td>
            )}
          </tr>
          <tr>
            {jsonSchema.properties.currentLocation.enabled && (
              <td className="edit-user-col">
                <Input
                  label={jsonSchema.properties.currentLocation.title}
                  sizeClass={uiSchema.currentLocation["ui:options"].sizeClass}
                  value={formData.currentLocation}
                  style={uiSchema.currentLocation["ui:options"].style}
                />
              </td>
            )}
            {jsonSchema.properties.contactNumber.enabled && (
              <td className="edit-user-col">
                <Input
                  label={jsonSchema.properties.contactNumber.title}
                  sizeClass={uiSchema.contactNumber["ui:options"].sizeClass}
                  value={formData.contactNumber}
                  style={uiSchema.contactNumber["ui:options"].style}
                />
              </td>
            )}
          </tr>
          {jsonSchema.properties.userStatus.enabled && (
            <tr>
              <td className="edit-user-col">
                <span>{jsonSchema.properties.userStatus.title}</span>
                <br />
                <span>
                  {editUserDetails?.activeStatus ? "Enabled" : "Disabled"}
                </span>
              </td>
            </tr>
          )}
          {jsonSchema.properties.rolesAndOperation.enabled && (
            <tr>
              <td className="edit-user-col">
                <b>{jsonSchema.properties.rolesAndOperation.title}</b>
              </td>
            </tr>
          )}
          <tr style={{ display: "flex", marginTop: "-8px" }}>
            {jsonSchema.properties.role.enabled && (
              <td className="edit-user-col">
                <Input
                  label={jsonSchema.properties.role.title}
                  sizeClass={uiSchema.role["ui:options"].sizeClass}
                  style={uiSchema.role["ui:options"].style}
                  value={editUserDetails?.role}
                />
              </td>
            )}
            {jsonSchema.properties.operationArea.enabled && (
              <td>
                <Dropdown
                  name="opsarea"
                  className={`drop-ops-area ${uiSchema.opsarea["ui:classNames"]}`}
                  style={{ paddingLeft: "16px" }}
                  label={jsonSchema.properties.operationArea.title}
                  monochrome={!uiSchema.opsarea["ui:options"].label}
                  edited={dpEdited}
                  multiselect={true}
                  value={opsSelectedPlace}
                  onChange={(val) => {
                    setDpEdited(true);
                    setOpsSelectedPlace(val);
                    getSubstations(substationTableData, val);
                  }}
                  initialOpen="multi"
                  {...commonFlags}
                >
                  {opsPlace.map((option) => (
                    <DropdownOption
                      key={option}
                      label={option}
                      value={option}
                    />
                  ))}
                </Dropdown>
              </td>
            )}
          </tr>
          {jsonSchema.properties.availableSubstation.enabled && (
            <tr>
              <td className="edit-user-col">
                <span>{jsonSchema.properties.availableSubstation.title}</span>
              </td>
            </tr>
          )}
          {jsonSchema.properties.availableSubstation.enabled && (
            <tr>
              <div style={{ maxWidth: "700px" }}>
              {filteredSubstations.map((substationItem) => {
  return (
    <span key={substationItem.id || substationItem.name} className="avail-substn">
      {substationItem.name}
    </span>
  );
})}
              </div>
            </tr>
          )}
 
          {jsonSchema.properties.setPermission.enabled && (
            <tr>
              <td className="edit-user-col">
                <b>{jsonSchema.properties.setPermission.title}</b>
              </td>
            </tr>
          )}
          {jsonSchema.properties.modifyuser.enabled && (
            <tr>
              <td className="edit-user-col">
                <span>{jsonSchema.properties.modifyuser.title}</span>
                <ToggleSwitch
                  name="modifyuser"
                  label={
                    updateUserValue
                      ? jsonSchema.properties.modifyuser.labels.true
                      : jsonSchema.properties.modifyuser.labels.false
                  }
                  value={updateUserValue}
                  onChange={(newState) => {
                    setUpdateUserValue(newState);
                    setOptions({ ...options, userManagement: newState });
                  }}
                  disabled={
                    uiSchema.modifyuser["ui:disabled"]
                      ? userRole !== "OperationalManager"
                      : false
                  }
                />
              </td>
            </tr>
          )}
          {jsonSchema.properties.caesml.enabled && (
            <td className="edit-user-col">
              <span>{jsonSchema.properties.caesml.title}</span>
              <ToggleSwitch
                name="caesml"
                label={
                  updateCaesMLFeedback
                    ? jsonSchema.properties.caesml.labels.true
                    : jsonSchema.properties.caesml.labels.false
                }
                value={updateCaesMLFeedback}
                disabled={uiSchema.caesml["ui:disabled"]}
              />
            </td>
          )}
 
          {jsonSchema.properties.accesmap.enabled && (
            <tr>
              <td className="edit-user-col">
                <span>{jsonSchema.properties.accesmap.title}</span>
                <ToggleSwitch
                  name="accesmap"
                  label={
                    accessSubMap
                      ? jsonSchema.properties.accesmap.labels.true
                      : jsonSchema.properties.accesmap.labels.false
                  }
                  value={accessSubMap}
                  onChange={(newState) => {
                    setAccessSubMap(newState);
                    setOptions({ ...options, addSubstationMapping: newState });
                  }}
                  disabled={
                    uiSchema.accesmap["ui:disabled"]
                      ? userRole !== "OperationalManager"
                      : false
                  }
                />
              </td>
            </tr>
          )}
          {jsonSchema.properties.accesexp.enabled && (
            <tr>
              <td className="edit-user-col">
                <span>{jsonSchema.properties.accesexp.title}</span>
                <ToggleSwitch
                  name="accesexp"
                  label={
                    accessSubExp
                      ? jsonSchema.properties.accesexp.labels.true
                      : jsonSchema.properties.accesexp.labels.false
                  }
                  value={accessSubExp}
                  onChange={(newState) => {
                    setAccessSubExp(newState);
                    setOptions({ ...options, substationExplorer: newState });
                  }}
                  disabled={uiSchema.accesexp["ui:disabled"]}
                />
              </td>
            </tr>
          )}
          {jsonSchema.properties.updatesld.enabled && (
            <tr>
              <td className="edit-user-col">
                <span>{jsonSchema.properties.updatesld.title}</span>
 
                <ToggleSwitch
                  name="updatesld"
                  label={
                    singleLineValue
                      ? jsonSchema.properties.updatesld.labels.true
                      : jsonSchema.properties.updatesld.labels.false
                  }
                  value={singleLineValue}
                  onChange={(newState) => {
                    setSingleLineValue(newState);
                    setOptions({
                      ...options,
                      uploadSingleLineDiagram: newState,
                    });
                  }}
                  disabled={
                    uiSchema.updatesld["ui:disabled"]
                      ? userRole === "Technician"
                      : false
                  }
                />
              </td>
            </tr>
          )}
          {jsonSchema.properties.ackdist.enabled && (
            <tr>
              <td className="edit-user-col">
                <span>{jsonSchema.properties.ackdist.title}</span>
                <ToggleSwitch
                  name="ackdist"
                  label={
                    acknowledgeValue
                      ? jsonSchema.properties.ackdist.labels.true
                      : jsonSchema.properties.ackdist.labels.false
                  }
                  value={acknowledgeValue}
                  onChange={(newState) => {
                    setAcknowledgeValue(newState);
                    setOptions({
                      ...options,
                      disturbanceRecordAcknowledgment: newState,
                    });
                  }}
                />
              </td>
            </tr>
          )}
          {jsonSchema.properties.drescalation.enabled && (
            <tr>
              <td className="edit-user-col">
                <span>{jsonSchema.properties.drescalation.title}</span>
                <ToggleSwitch
                  name="drescalation"
                  label={
                    escalationValue
                      ? jsonSchema.properties.drescalation.labels.true
                      : jsonSchema.properties.drescalation.labels.false
                  }
                  value={escalationValue}
                  onChange={(newState) => {
                    setEscalationValue(newState);
                    setOptions({
                      ...options,
                      disturbanceRecordEscalationNotification: newState,
                    });
                  }}
                  disabled={
                    uiSchema.drescalation["ui:disabled"]
                      ? userRole !== "OperationalManager"
                      : false
                  }
                />
              </td>
            </tr>
          )}
          <tr>
            <td className="edit-user-col">
              {jsonSchema.properties.SaveButton.enabled && (
                <Button
                  text={jsonSchema.properties.SaveButton.const}
                  disabled={!isSaveEnabled}
                  shape={"pill"}
                  sizeClass={"small"}
                  type={"primary-black"}
                  onClick={() => handleSaveUserInfo()}
                />
              )}
 
              {jsonSchema.properties.CancelButton.enabled && (
                <Button
                  text={
                    jsonSchema.properties.CancelButton.const
                  }
                  shape={"pill"}
                  sizeClass={"small"}
                  onClick={goToSettingsFromSave}
                />
              )}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};
 
export default EditUser;