import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import axios from "axios";
import { BASE_URL, MESSAGES } from "../../strings";
import { getToken } from "../../components/Authentication/get-token";
const RbacContext = createContext();

export const RbacProvider = ({ children }) => {
  const [rbacData, setRbacData] = useState(null);

  // Utility function to handle storing data in localStorage
  const storeDataInLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  const fetchRbacData = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + `rbac-get`, getToken());
      const data = response.data;
      setRbacData(data);
      storeDataInLocalStorage("rbacManagerUsersList", data);
    } catch (error) {
      console.error("Error fetching RBAC data:", error);
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({ rbacData, setRbacData, fetchRbacData }),
    [rbacData, fetchRbacData]
  );

  return (
    <RbacContext.Provider value={memoizedValue}>
      {children}
    </RbacContext.Provider>
  );
};

// Export the custom hook
export const useRbac = () => {
  const context = useContext(RbacContext);
  if (!context) {
    throw new Error("useRbac must be used within an RbacProvider");
  }
  return context;
};
